import PrivacyPolicyMdx from "mm-privacy-policy/components/PrivacyPolicy.mdx";
import TermsOfUseMdx from "mm-privacy-policy/components/TermsOfUse.mdx";
import React from "react";
import Content from "mm-threads/components/Content";

const PROPS = {
  domain: "informatted.com",
  weUs: `INFORMATTED ("Zigimus, LLC", "Informatted", "we", and "us")`,
  legalName: "Zigimus, LLC",
  legalNamePossessive: "Zigimus LLC's",
  name: "Informatted",
  namePossessive: "Informatted's",
  theServices: `website(s), products, services, and applications (collectively, the "Services")`,
  contactEmail: "contact@zigimus.com"
};

export function PrivacyPolicy() {
  return (
    <Content style={{ paddingLeft: 25, paddingRight: 25, paddingTop: 25 }}>
      <PrivacyPolicyMdx {...PROPS} />
    </Content>
  );
}

export function TermsOfUse() {
  return (
    <Content style={{ paddingLeft: 25, paddingRight: 25, paddingTop: 25 }}>
      <TermsOfUseMdx {...PROPS} />
    </Content>
  );
}
