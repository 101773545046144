import * as ds from "mm-ds";
import { apiCall } from "mm-api";

export type Story = {
  id: string;
  title: string;
  user: string;
  time: number;
  time_ago: string;
  type: string;
  points: number;
  comments_count: number;
  domain: string;
  url: string;
  comments: Comment[];
};

export type Comment = {
  id: string;
  level: number;
  user: string;
  time: number;
  time_ago: string;
  content: string;
  comments?: Comment[];
};

export const useStory = ds.wrap(
  (id: string): Story => {
    const response = ds.usePromise(
      async () => {
        return await apiCall({
          method: "GET",
          json: true,
          url: `https://api.hackerwebapp.com/item/${id}`,
          timeout: 5000,
          throwOnError: true
        });
      },
      ["story", id]
    );
    return response.body as Story;
  }
);

export const useStories = ds.wrap(
  (): Story[] => {
    const response = ds.usePromise(
      async () => {
        return await apiCall({
          method: "GET",
          json: true,
          url: `https://api.hackerwebapp.com/news`,
          timeout: 5000,
          throwOnError: true
        });
      },
      ["stories"]
    );

    return response.body.filter(item => item.type !== "job");
  }
);

export const stories = ds.create(ctx => {
  return useStories();
  // const response = ctx.cache(
  //   "stories",
  //   () =>
  //     apiCall({
  //       method: "GET",
  //       json: true,
  //       url: "https://api.hackerwebapp.com/news",
  //       timeout: 5000,
  //       throwOnError: true
  //     }),
  //   { ttl: 60 * 1000 }
  // );
  //
  // return response.body.filter(item => item.type !== "job").map(item => {
  //   const url = `https://news.ycombinator.com/item?id=${item.id}`;
  //   return {
  //     ...item,
  //     url
  //   };
  // });
});
