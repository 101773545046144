import { useTheme } from "mm-themes/components/Theme";
import Badge from "mm-threads/components/Badge";
import Cell from "mm-threads/components/Cell";
import Command from "mm-threads/components/Command";
import Content from "mm-threads/components/Content";
import Title from "mm-threads/components/Title";
import React, { useState } from "react";

function Expand(props: {
  indent?: number;
  id: string;
  children: React.ReactNode;
  expanded?: boolean;
}) {
  const [expanded, setExpanded] = useState(!!props.expanded);
  const indent = props.indent || 0;

  return <>{props.children}</>;

  /* return (
   *   <>
   *     <Cell indent={indent}>
   *       <Command
   *         icon={expanded ? "expand-less" : "expand-more"}
   *         label={expanded ? "Hide Examples" : "See Examples"}
   *         eventAction={expanded ? "expand-less" : "expand-more"}
   *         eventLabel={props.id}
   *         onCommand={() => setExpanded(!expanded)}
   *       />
   *     </Cell>
   *     <div key="wrapper" style={{ display: expanded ? "block" : "none" }}>
   *       {props.children}
   *     </div>
   *   </>
   * ); */
}

export default function Home() {
  const [theme] = useTheme();

  return (
    <>
      <Cell indent={0}>
        <Title>Start the conversation</Title>
        <Content>
          <Content>
            <p>
              Informatted lets your business create polls, surveys, and comment
              threads online.
            </p>
          </Content>
        </Content>
        <Command
          icon="send"
          label="Get Started"
          color={theme.primary}
          to="/beta"
        />
      </Cell>

      <Cell indent={0}>
        <Title>Make A Quick Poll</Title>
        <Content>
          <p>Want to create a slack poll? - it's as easy as pasting a link!</p>
        </Content>
      </Cell>

      <Expand indent={1} id="make-a-poll">
        <Cell indent={1}>
          <Title>Example: Project Priorities</Title>
        </Cell>

        <Cell indent={2}>
          <Title level={3}>Mobile Support</Title>
          <Badge icon="show-chart" label="score" value="82 votes" />
          <Command icon="comment" label="18 Comments" />
        </Cell>

        <Cell indent={2}>
          <Title level={3}>Improved Stability</Title>
          <Badge icon="show-chart" label="score" value="30 votes" />
          <Command icon="comment" label="5 Comments" />
        </Cell>

        <Cell indent={2}>
          <Title level={3}>International Support</Title>
          <Badge icon="show-chart" label="score" value="15 votes" />
          <Command icon="comment" label="10 Comments" />
        </Cell>
      </Expand>

      <Cell indent={1}>
        <Command
          icon="send"
          label="Make My First Poll"
          color={theme.primary}
          to="/beta"
        />
      </Cell>

      <Cell indent={0}>
        <Title>Ask Your Clients</Title>
        <Content>
          <p>Get insights and solicit feedback from your best customers.</p>
        </Content>
      </Cell>

      <Expand indent={1} id="engage-customers">
        <Cell indent={1}>
          <Title>Example: Features Requests</Title>
        </Cell>

        <Cell indent={2}>
          <Title level={3}>Android Support</Title>
          <Badge icon="show-chart" label="score" value="512 votes" />
          <Command icon="comment" label="25 Comments" />
        </Cell>

        <Cell indent={2}>
          <Title level={3}>Slack Integration</Title>
          <Badge icon="show-chart" label="score" value="188 votes" />
          <Command icon="comment" label="21 Comments" />
        </Cell>

        <Cell indent={2}>
          <Title level={3}>Better Analytics</Title>
          <Badge icon="show-chart" label="score" value="150 votes" />
          <Command icon="comment" label="15 Comments" />
        </Cell>

        <Cell indent={2}>
          <Title level={3}>Share Extension</Title>
          <Badge icon="show-chart" label="score" value="22 votes" />
          <Command icon="comment" label="2 Comments" />
        </Cell>
      </Expand>

      <Cell indent={1}>
        <Command
          icon="send"
          label="Collect Feature Requests"
          color={theme.primary}
          to="/beta"
        />
      </Cell>

      <Cell indent={0}>
        <Title>Engage your Team</Title>
        <Content>
          <p>Ask for feedback from your manager and coworkers.</p>
        </Content>
      </Cell>

      <Expand indent={1} id="engage-team">
        <Cell indent={1}>
          <Title>Example: Questions for the All Hands Meeting</Title>
        </Cell>

        <Cell indent={2}>
          <Title level={3}>What's our work from home policy?</Title>
          <Badge icon="show-chart" label="score" value="12 votes" />
          <Badge icon="person" label="creator" value="JaneJ" />
          <Command icon="comment" label="18 Comments" />
        </Cell>

        <Cell indent={2}>
          <Title level={3}>When will the new office be finished?</Title>
          <Badge icon="show-chart" label="score" value="5 votes" />
          <Badge icon="person" label="creator" value="ChrisT" />
          <Command icon="comment" label="5 Comments" />
        </Cell>

        <Cell indent={2}>
          <Title level={3}>Any update on fundraising?</Title>
          <Badge
            icon="show-chart"
            label="score"
            value="No votes yet"
            to="/beta"
          />
          <Badge icon="person" label="creator" value="Megan" />
          <Command icon="comment" label="10 Comments" />
        </Cell>

        {/* <Cell indent={1}>
            <Title>Example: Anonymous Questions</Title>
            </Cell>

            <Cell indent={2}>
            <Title level={3}>Why did we pause hiring?</Title>
            </Cell>

            <Cell indent={2}>
            <Title level={3}>Is this a pivot?</Title>
            </Cell>

            <Cell indent={2}>
            <Title level={3}>Any update on fundraising?</Title>
            </Cell> */}
      </Expand>

      <Cell indent={1}>
        <Command
          icon="send"
          label="Ask For Feedback"
          color={theme.primary}
          to="/beta"
        />
      </Cell>

      <Cell indent={0}>
        <Title>Create a Survey</Title>
      </Cell>

      <Expand indent={1} id="create-survey">
        <Cell indent={1}>
          <Title>Example: Podcast Survey</Title>
        </Cell>
        <Cell indent={2}>
          <Title level={3}>How often do you listen to the podcast?</Title>
          <Command icon="comment" label="Every week" />
          <Command icon="comment" label="Almost every week" />
          <Command icon="comment" label="Occasionally" />
          <Command icon="comment" label="Rarely" />
        </Cell>

        <Cell indent={2}>
          <Title level={3}>
            How often do you read the highlights for an episode?
          </Title>
          <Command icon="comment" label="Every episode" />
          <Command icon="comment" label="Almost every episode" />
          <Command icon="comment" label="Occasionally" />
          <Command icon="comment" label="Rarely" />
          <Command icon="comment" label="Never" />
        </Cell>

        <Cell indent={2}>
          <Title level={3}>Where do you listen to the podcast most?</Title>
          <Command icon="comment" label="Commuting" />
          <Command icon="comment" label="Exercising" />
          <Command icon="comment" label="At work" />
          <Command icon="comment" label="Walking the dog" />
          <Command icon="comment" label="In bed before going to sleep" />
        </Cell>
      </Expand>

      <Cell indent={1}>
        <Command
          icon="send"
          label="Make My First Survey"
          color={theme.primary}
          to="/beta"
        />
      </Cell>

      {/* <Cell indent={1}>
          <Command icon="send" label="Start a Discussion" color={theme.primary} />
          </Cell> */}

      <Cell indent={0}>
        <Title>Engage your Audience</Title>
        <Content>
          <p>
            Grow your revenue and engage your audience with exclusive
            discussions
          </p>
        </Content>
      </Cell>

      <Expand indent={1} id="engage-audience">
        <Cell indent={1}>
          <Title>Example: Ask Me Anything (AMA)</Title>
        </Cell>

        <Cell indent={2}>
          <Title level={3}>How do you choose what to focus on?</Title>
          <Badge icon="show-chart" label="score" value="12 votes" />
          <Badge icon="person" label="creator" value="JimmyK" />
        </Cell>

        <Cell indent={2}>
          <Title level={3}>What's your morning routine?</Title>
          <Badge icon="show-chart" label="score" value="5 votes" />
          <Badge icon="person" label="creator" value="SusanP" />
        </Cell>
      </Expand>

      <Cell indent={1}>
        <Command
          icon="send"
          label="Start a Discussion"
          color={theme.primary}
          to="/beta"
        />
      </Cell>

      {/* <Cell indent={1}>
          <Command icon="send" label="Start a Discussion" color={theme.primary} />
          </Cell> */}
    </>
  );
}
