import _ from "lodash";
import firebase from "firebase";
import React from "react";

const { useState, useEffect } = React;

function normalize(key: string | string[]): string[] {
  const keyarr = typeof key === "string" ? [key] : key;
  return keyarr;
}

function load(key: string | string[]) {
  const db = firebase.database();

  let result = db.ref();
  for (let subkey of normalize(key)) {
    result = result.child(subkey);
  }
  return result;
}

export function useCollectionHelpers<T extends { id: string }>(
  key: string | string[]
): [(collection: Partial<T>) => void, (id: string) => void] {
  const ref = load(key);

  const upsert = (collection: Partial<T>) => {
    if (collection.id) {
      ref.child(collection.id).set(collection);
    } else {
      ref.push(collection);
    }
  };

  const remove = (id: string) => {
    ref.child(id).remove();
  };

  return [upsert, remove];
}

export function useCollection<T extends { id: string }>(
  key: string | string[],
  mapper?: (value: Partial<T>) => Partial<T>
): [Array<T>, (collection: Partial<T>) => void, (id: string) => void] {
  if (!mapper) {
    mapper = v => v;
  }

  const ref = load(key);
  const [collections, setCollections] = useState([]);
  const [collectionsMap] = useState(new Map<string, T>());
  const [upsert, remove] = useCollectionHelpers(key);

  useEffect(() => {
    let handlers = {};

    for (let event of ["child_added", "child_changed"]) {
      handlers[event] = ref.on(event as any, snapshot => {
        const key = snapshot.key;
        const val = { id: key, ...snapshot.val() };
        /* console.log(event, { key, val }); */
        collectionsMap.set(key, mapper(val) as T);
        setCollections([...collectionsMap.values()]);
      });
    }

    handlers["child_removed"] = ref.on("child_removed", snapshot => {
      const key = snapshot.key;
      const val = snapshot.val();
      /* console.log("child_removed", { key, val }); */
      collectionsMap.delete(key);
      setCollections([...collectionsMap.values()]);
    });

    return () => {
      for (let [event, handler] of _.entries(handlers)) {
        ref.off(event as any, handler as any);
      }
    };
  }, normalize(key));

  return [collections, upsert, remove];
}

export function useModel<T extends { id: string }>(
  key: string | string[],
  mapper?: (value: Partial<T>) => Partial<T>
): [T | null, (model: Partial<T>) => void] {
  if (!mapper) {
    mapper = v => v;
  }

  const keyarr = normalize(key);
  const [id] = keyarr.slice(-1);

  const ref = load(keyarr);
  const [model, setModel] = useState<T | null>(null);

  useEffect(() => {
    let handler = ref.on("value", snapshot => {
      const val = snapshot.val();
      console.log({ key, name: "useModel", val, id });
      if (!val) {
        setModel(null);
      } else {
        const model = mapper({ ...val, id }) as T;
        setModel(model);
      }
    });
    return () => {
      ref.off("value", handler);
    };
  }, normalize(key));

  const upsert = (model: Partial<T>) => {
    ref.set(mapper(model));
  };

  // const remove = (id: string) => {
  //   gun.get(id).put(null);
  // };

  return [model, upsert];
}
