import React from "react";

import mat from "react-native-vector-icons/MaterialIcons";
import fa5 from "react-native-vector-icons/FontAwesome5";

const COLLECTIONS = { mat, fa5 };

export default function Icon(props: {
  name: string;
  size?: number;
  color?: string;
  style?: any;
}) {
  let collection = "mat";
  let name = props.name;
  if (name.indexOf(" ") > 0) {
    [collection, name] = name.split(" ");
  }
  const Icon = COLLECTIONS[collection] || mat;
  // console.log({ collection, name, Icon });
  return <Icon {...props} name={name} />;
}
