import Icon from "mm-icons/components/Icon";
import Link from "mm-router/components/Link";
import { useTheme, ColorType } from "mm-themes/components/Theme";
import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { block } from "./Cell";
import { beacon } from "mm-analytics";

export type Props = {
  icon: string;
  label: string;
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
  to?: string;
  color?: ColorType;
  onCommand?: (...args: any) => void;
  type?: string;
};

function Command(props: Props) {
  const [theme] = useTheme();
  const color = props.color || theme.secondary;

  function onCommand() {
    if (props.onCommand) {
      props.onCommand();
    }
    let eventCategory = props.eventCategory || "Command";
    let eventAction = props.eventAction || "click";
    let eventLabel = props.eventLabel || props.label;

    console.log("ga", "send", {
      hitType: "event",
      eventCategory,
      eventAction,
      eventLabel
    });

    beacon("send", {
      hitType: "event",
      eventCategory,
      eventAction,
      eventLabel
    });
  }

  let result = (
    <View
      style={{
        flexDirection: "row",
        backgroundColor: color.lightest,
        borderColor: color.darkest,
        borderWidth: 1,
        borderRadius: 5,
        paddingVertical: 3,
        paddingHorizontal: 10,
        margin: 5,
        marginLeft: 0,
        alignItems: "center"
      }}
      accessible
      accessibilityLabel={props.label}
    >
      <Icon name={props.icon} size={18} color={color.text.lighter} />
      <Text
        style={{
          color: color.text.lighter,
          paddingLeft: 6,
          textAlignVertical: "center",
          fontFamily: theme.headerFontFamily[0]
        }}
      >
        {props.label}
      </Text>
    </View>
  );

  if (props.to) {
    result = (
      <Link to={props.to} onClick={onCommand}>
        {result}
      </Link>
    );
  } else {
    result = <TouchableOpacity onPress={onCommand}>{result}</TouchableOpacity>;
  }

  return result;
}

export default block(Command, other => other == Command);
