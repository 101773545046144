import { ThemeType } from "./Theme";
import React from "react";
import { Helmet } from "react-helmet";

export default function Load(props: { theme: ThemeType }) {
  const theme = props.theme;
  let fontsStr = "";

  const fonts = theme.googleFonts.map(font => {
    let str = "";
    str += font.name.split(" ").join("+");
    str += ":";
    str += font.styles.join(",");

    return str;
  });

  fontsStr = fonts.join("|");

  const fontLink = fontsStr ? (
    <link
      href={`//fonts.googleapis.com/css?family=${fontsStr}`}
      rel={"stylesheet"}
      type={"text/css"}
    />
  ) : null;

  return (
    <Helmet>
      <style type="text/css">
        {`
          ${theme.typography}
          body {
            background-color: ${theme.content.base};
          }
         `}
      </style>
      <style type="text/css">
        {`

        @font-face {
          src: url(${require("react-native-vector-icons/Fonts/MaterialIcons.ttf")}) format('truetype');
          font-family: MaterialIcons;
        }

        @font-face {
          src: url(${require("react-native-vector-icons/Fonts/FontAwesome5_Solid.ttf")}) format('truetype');
          font-family: FontAwesome5_Solid;
        }

        @font-face {
          src: url(${require("react-native-vector-icons/Fonts/FontAwesome5_Brands.ttf")}) format('truetype');
          font-family: FontAwesome5_Brands;
        }
         `}
      </style>
      {fontLink}
    </Helmet>
  );
}
