import { css, parent } from "glamor";
import { useColor } from "mm-themes/components/Color";
import React, { useMemo } from "react";
import { block } from "./Cell";

function Content(props: {
  padding?: number;
  children: React.ReactNode;
  style?: any;
}) {
  const color = useColor();

  const style = useMemo(() => {
    return css(
      {
        color: color.text.base,
        paddingTop: 5
      },
      parent("h1,h2,h3,h4,h5", {
        color: color.text.lighter,
        margin: 0
      }),
      parent("pre,p,span,div", { color: color.text.base })
    );
  }, [color.base, color.text.base, props.padding]);

  return (
    <div className={`${style}`} style={props.style}>
      {props.children}
    </div>
  );
}

export default block(Content);
