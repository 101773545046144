import { ActivityIndicator } from "react-native";
import React from "react";
import { useTheme } from "mm-themes/components/Theme";

export default function Loading() {
  const [theme] = useTheme();
  return (
    <ActivityIndicator
      key="pending"
      size="large"
      style={{ padding: 25 }}
      color={theme.primary.base}
    />
  );
}
