import { useRouter } from "mm-router/hooks";
import Cell from "mm-threads/components/Cell";
import Command from "mm-threads/components/Command";
import Title from "mm-threads/components/Title";
import React from "react";
import Content from "mm-threads/components/Content";
import { NewBoard } from "../threads/boards";
import Badge from "mm-threads/components/Badge";
import { useTheme } from "mm-themes/components/Theme";
import { useEntityForm } from "mm-threads/hooks";

export default function Pricing() {
  const form = useEntityForm("createBoard", () => ({ content: "", title: "" }));
  const [theme] = useTheme();
  const { history } = useRouter();

  const onCreate = (id: string) => {
    // console.log("onCreate", { id });
    history.push(`/boards/${id}`);
  };

  return (
    <>
      <Cell indent={0}>
        <Title>Plans and Pricing</Title>
      </Cell>

      <Cell indent={1}>
        <Title level={1}>Free</Title>
        <Badge icon="person" label="5 Active Users" />
        <Badge icon="show-chart" label="15 Boards Per Month" />
        <Content>
          <ul>
            <li>Start creating boards right now.</li>
            <li>Up to 5 users can participate on each board.</li>
          </ul>
        </Content>
        {/* <Command icon="assessment" label="Try It Now" onCommand={form.toggle} /> */}
        <Command icon="assessment" label="Try It Now" to="/beta" />
      </Cell>

      <NewBoard form={form} indent={2} onCreate={onCreate} />

      <Cell indent={1}>
        <Title level={1}>Standard</Title>
        <Badge icon="monetization-on" label="$50 per month" />
        <Badge icon="person" label="50 Active Users" />
        <Badge icon="show-chart" label="Unlimited Boards" />
        <Badge icon="thumb-up" label="Recommended" />
        <Content>
          <ul>
            <li>Create as many boards as you like</li>
            <li>
              Up to 50 users can actively participate (e.g. comment or vote) on
              each board.
            </li>
            <li>Add more users for $1 per user per month.</li>
          </ul>
        </Content>
        <Command
          icon="send"
          label="Get Started"
          color={theme.primary}
          to="/beta"
        />
      </Cell>

      <Cell indent={1}>
        <Title level={1}>Professional</Title>
        <Badge icon="monetization-on" label="$250 per month" />
        <Badge icon="person" label="500 Active Users" />
        <Badge icon="show-chart" label="Unlimited Boards" />
        <Content>
          <ul>
            <li>
              Up to 500 users can actively participate (e.g. comment or vote) on
              each board.
            </li>
            <li>Add more users for $0.40 per user per month.</li>
          </ul>
        </Content>
        <Command icon="send" label="Get Started" to="/beta" />
      </Cell>

      <Cell indent={1}>
        <Title level={1}>Enterprise</Title>
        <Badge icon="person" label="Unlimited Users" />
        <Badge icon="fa5 server" label="Self Hosted or Cloud Hosted" />
        <Badge icon="sentiment-satisfied" label="Priority Support" />
        <Command icon="email" label="Contact Us" to="/beta" />
      </Cell>
    </>
  );
}
