import React from "react";
import {
  ApolloClient,
  ApolloProvider as ApolloProviderImpl
} from "mm-graphql-client";

const { useState } = React;

import firebase from "firebase";

export default function ApolloProvider(props: { children: React.ReactNode }) {
  const [client] = useState(
    () =>
      new ApolloClient({
        uri: "/graphql",
        request: async operation => {
          let currentUser = firebase.auth().currentUser;
          console.log("running graphql wiht ", { currentUser });
          if (currentUser) {
            let token = await currentUser.getIdToken();
            operation.setContext({
              headers: {
                "X-Client": "Browser",
                Authorization: `Bearer ${token}`
              }
            });
          } else {
            operation.setContext({
              headers: {
                "X-Client": "Browser"
              }
            });
          }
        }
      })
  );

  return (
    <ApolloProviderImpl client={client}> {props.children} </ApolloProviderImpl>
  );
}
