import { observable, computed, runInAction } from "mobx";

export default class Lifecycle {
  @observable
  mountingPromise: null | Promise<any> = null;
  @observable
  unmountingPromise: null | Promise<any> = null;
  @observable
  mounted = false;

  static inline({
    willUnmount,
    willMount,
    didMount
  }: {
    willUnmount?: Function;
    willMount?: Function;
    didMount?: Function;
  }): Lifecycle {
    class resultKlass extends Lifecycle {
      async willMount() {
        if (willMount != null) {
          await willMount();
        }
      }

      async willUnmount() {
        if (willUnmount != null) {
          await willUnmount();
        }
      }

      async didMount() {
        if (didMount != null) {
          await didMount();
        }
      }
    }
    return new resultKlass();
  }

  static mixins = {
    willUnmount: [],
    willMount: [],
    didMount: []
  };

  @computed
  get mounting(): boolean {
    return !!this.mountingPromise;
  }

  async willUnmount(): Promise<void> { }

  async willMount(): Promise<void> { }

  async didMount(): Promise<void> { }

  async _performDidMount() {
    try {
      //@ts-ignore
      for (let fn of this.constructor.mixins.didMount) {
        await fn.apply(this);
      }
      await this.didMount();
    } catch (e) {
      console.warn(e, "Store didMount error", this);
    }
  }

  async mount() {
    if (!this.mounted && !this.mountingPromise) {
      this.mountingPromise = (async () => {
        try {
          // console.log("store-> mounting ", this);
          //@ts-ignore
          for (let fn of this.constructor.mixins.willMount) {
            await fn.apply(this);
          }
          await this.willMount();
          this.mounted = true;
          this._performDidMount();
        } catch (e) {
          console.warn(e, "Store willMount error", this);
        } finally {
          this.mountingPromise = null;
        }
      })();
    }
    if (this.mountingPromise) {
      await this.mountingPromise;
    }
  }

  async unmount() {
    if (this.mounted && !this.unmountingPromise) {
      this.unmountingPromise = (async () => {
        try {
          await this.willUnmount();
        } catch (e) {
          console.warn(e, "Store willUnmount error", this);
        }

        //@ts-ignore
        for (let fn of this.constructor.mixins.willUnmount) {
          try {
            await fn.apply(this);
          } catch (e) {
            console.warn(e, "Store willUnmount mixin error", this);
          }
        }

        runInAction(() => {
          this.mounted = false;
          this.unmountingPromise = null;
        });
      })();
    }
    if (this.unmountingPromise) {
      await this.unmountingPromise;
    }
  }
}
