import firebase from "firebase";
import _ from "lodash";
import { useFirebaseUser } from "mm-firebase-client/hooks";
import Badge from "mm-threads/components/Badge";
import Command from "mm-threads/components/Command";
import React, { useMemo } from "react";

import { User } from "../types";

export function sanitize(key: string): string {
  return key.replace(/\./, "-dot-");
}

export const PROVIDERS = {
  github: new firebase.auth.GithubAuthProvider()
  // twitter: new firebase.auth.TwitterAuthProvider()
};

export function useCurrentUser(): User {
  const firebaseUser = useFirebaseUser();
  const user = useMemo(() => {
    let result: User = {};
    if (firebaseUser != null) {
      for (let data of firebaseUser.providerData || []) {
        for (let [id, provider] of _.entries(PROVIDERS)) {
          if (provider.providerId === data.providerId) {
            result[id] = {
              uid: data.uid,
              displayName: data.displayName,
              photoURL: data.photoURL
            };
          }
        }
      }
    }
    return result;
  }, [firebaseUser && firebaseUser.uid]);
  return user;
}

export default function UserBadges(props: { user?: User | undefined }) {
  return (
    <>
      {_.entries(PROVIDERS).map(([id]) => {
        const identity = (props.user || {})[id];
        if (!identity) {
          return null;
        }

        return (
          <Badge
            key={`badge-${id}`}
            icon={`fa5 ${id}`}
            label={id}
            value={identity.displayName}
          />
        );
      })}
    </>
  );
}

export function UserHeader() {
  const user = useCurrentUser();
  const isLoggedIn = !!_.keys(PROVIDERS).filter(id => user && user[id])[0];

  let children = [];

  for (let [id, provider] of _.entries(PROVIDERS)) {
    const identity = user[id as keyof User];

    if (!isLoggedIn) {
      children.push(
        <Command
          key={`add-${id}`}
          icon={`fa5 ${id}`}
          label={`Sign in with ${id}`}
          onCommand={async () => {
            await firebase.auth().signInWithPopup(provider);
          }}
        />
      );
    }

    if (identity) {
      children.push(
        <Command
          key={`badge-${id}`}
          icon={`fa5 ${id}`}
          label={identity.displayName}
          to="/profile"
        />
      );
    } else {
    }
  }
  return <>{children}</>;
}

export function AddUserBadges(props: {
  user: User | null;
  onUpdate?: (user: User) => void;
}) {
  const pending = props.user || {};
  let badges = [];
  let commands = [];

  for (let [id, provider] of _.entries(PROVIDERS)) {
    const identity = (props.user || {})[id as keyof User];
    if (identity) {
      badges.push([
        <Badge
          key={`badge-${id}`}
          icon={`fa5 ${id}`}
          label={id}
          value={identity.displayName}
        />
      ]);
      commands.push(
        <Command
          key={`remove-${id}`}
          icon={`fa5 ${id}`}
          label={`Remove ${id}`}
          onCommand={async () => {
            await firebase.auth().signOut();
          }}
        />
      );
    } else {
      commands.push(
        <Command
          key={`add-${id}`}
          icon={`fa5 ${id}`}
          label={`Add ${id}`}
          onCommand={async () => {
            await firebase.auth().signInWithPopup(provider);
          }}
        />
      );
    }
  }

  return <>{[...badges, ...commands]}</>;
}
