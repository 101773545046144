import React from "react";
import { View } from "react-native";

export default function Row(props: {
  children?: React.ReactNode;
  style?: any;
}) {
  return (
    <View
      style={[
        props.style,
        {
          flexDirection: "row",
          paddingHorizontal: 5,
          paddingVertical: 0,
          flexWrap: "wrap"
        }
      ]}
    >
      {props.children}
    </View>
  );
}
