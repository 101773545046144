import "@babel/polyfill";
import React from "react";
//@ts-ignore
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import Loading from "mm-threads/components/Loading";
import { View } from "react-native";

const isProd = process.env.NODE_ENV === "production";
const project = process.env.PROJECT || "mm-actions";
const Router = require("../" + project + "/Router.tsx").default;

const Suspense = React.Suspense || React.Fragment;

function App() {
  return (
    <View
      style={{
        width: "100%"
      }}
    >
      <Suspense fallback={<Loading />}>
        <BrowserRouter basename={isProd ? "/" : `/${project}`}>
          <Router />
        </BrowserRouter>
      </Suspense>
    </View>
  );
}

//@ts-ignore
ReactDOM.render(<App />, document.getElementById("app"));
