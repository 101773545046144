import _ from "lodash";

export function mixin(target: any, name: any, handler: any) {
  const cmixin = _.get(target, ["constructor", "mixins", name]);
  if (cmixin != null) {
    // console.log("target supports mixin directly", {
    //   target,
    //   name,
    //   constructor: target.constructor.name
    // });
    target.constructor.mixins = {
      ...target.constructor.mixins,
      [name]: [...target.constructor.mixins[name], handler]
    };
    return;
  }

  const mixinKey = `__mixin_${name}`;

  if (!target[mixinKey]) {
    if (target[name]) {
      target[mixinKey] = [target[name]];
    } else {
      target[mixinKey] = [];
    }
  }

  target[mixinKey].push(handler);

  target[name] = function(...args) {
    for (let fn of target[mixinKey]) {
      fn.apply(this, args);
    }
  };
}
