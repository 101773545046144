import _ from "lodash";

function encode(val) {
  return encodeURIComponent(val)
    .replace(/%40/gi, "@")
    .replace(/%3A/gi, ":")
    .replace(/%24/g, "$")
    .replace(/%2C/gi, ",")
    .replace(/%20/g, "+")
    .replace(/%5B/gi, "[")
    .replace(/%5D/gi, "]");
}

function forEach(obj, fn) {
  // Don't bother if no value provided
  if (obj === null || typeof obj === "undefined") {
    return;
  }

  // Force an array if not already something iterable
  if (typeof obj !== "object") {
    /*eslint no-param-reassign:0*/
    obj = [obj];
  }

  if (_.isArray(obj)) {
    // Iterate over array values
    for (var i = 0, l = obj.length; i < l; i++) {
      fn.call(null, obj[i], i, obj);
    }
  } else {
    // Iterate over object keys
    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        fn.call(null, obj[key], key, obj);
      }
    }
  }
}
export function buildURL(url: string, params: { [key: string]: any } = {}) {
  /*eslint no-param-reassign:0*/
  if (!params) {
    return url;
  }

  let serializedParams = null;

  var parts = [];

  for (let [key, val] of _.entries(params)) {
    if (val === null || typeof val === "undefined") {
      return;
    }
    if (_.isArray(val)) {
      key = key + "[]";
    } else {
      val = [val];
    }

    forEach(val, function parseValue(v: any) {
      if (_.isDate(v)) {
        v = v.toISOString();
      } else if (_.isObject(v)) {
        v = JSON.stringify(v);
      }
      parts.push(encode(key) + "=" + encode(v));
    });
  }

  serializedParams = parts.join("&");

  if (serializedParams) {
    var hashmarkIndex = url.indexOf("#");
    if (hashmarkIndex !== -1) {
      url = url.slice(0, hashmarkIndex);
    }

    url += (url.indexOf("?") === -1 ? "?" : "&") + serializedParams;
  }

  return url;
}
