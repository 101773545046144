import _ from "lodash";

export function replaceAll(
  str: string,
  find: string,
  replacement: string
): string {
  return str.replace(new RegExp(_.escapeRegExp(find), "g"), replacement);
}

export function btoa(str: string | Buffer): string {
  var buffer;

  if (str instanceof Buffer) {
    buffer = str;
  } else {
    buffer = new Buffer(str.toString(), "binary");
  }

  return buffer.toString("base64");
}

export function firstLetterUpper(value: string | null): string {
  if (value && value.length > 0) {
    return value.substr(0, 1).toUpperCase() + value.substr(1);
  }
  return "";
}

export function dashesToCapitalize(value: string): string {
  return value
    .split("-")
    .map(v => _.capitalize(v))
    .join(" ");
}

export function dashesToUnderscore(value: string): string {
  return value.split("-").join("_");
}

export function dashesToCamel(value: string): string {
  return value
    .split("-")
    .map(v => _.capitalize(v))
    .join("");
}
