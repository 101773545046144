import Icon from "mm-icons/components/Icon";
import React from "react";
import { Text, View, TouchableOpacity } from "react-native";
import { block } from "./Cell";
import { useTheme } from "mm-themes/components/Theme";
import Link from "mm-router/components/Link";

function Badge(props: {
  icon: string;
  to?: string;
  onCommand?: (...args: any) => void;
  label: string;
  value?: number | string | { type: "url"; url: string };
  style?: any;
}) {
  const [theme] = useTheme();
  let { value, label } = props;
  let extraProps = {};

  let url: string | null = null;

  if (typeof value === "object" && value.type === "url") {
    url = value.url;
  }

  if (props.to) {
    url = props.to;
  }

  let text = value ? value : label;

  let result = (
    <View
      style={[
        {
          flexDirection: "row",
          backgroundColor: theme.content.lightest,
          borderColor: theme.content.darkest,
          borderWidth: 1,
          borderRadius: 5,
          margin: 5,
          marginLeft: 0,
          paddingVertical: 3,
          paddingHorizontal: 6,
          alignItems: "center"
        },
        props.style
      ]}
      accessible
      accessibilityLabel={props.label}
      {...extraProps}
    >
      <Icon name={props.icon} size={18} color={theme.content.text.light} />
      <Text
        style={{
          fontFamily: theme.bodyFontFamily[0],
          color: theme.content.text.light,
          paddingLeft: 6,
          textAlignVertical: "center"
        }}
      >
        {text}
      </Text>
    </View>
  );

  if (url) {
    result = <Link to={url}>{result}</Link>;
  }

  if (props.onCommand) {
    result = (
      <TouchableOpacity onPress={props.onCommand}>{result}</TouchableOpacity>
    );
  }

  return result;
}

export default block(Badge, other => {
  return other == Badge;
});
