import React from "react";
import { User } from "firebase";

const { useContext } = React;

export const UserContext = React.createContext<User | null>(null);

export function useFirebaseUser(): User | null {
  const user = useContext(UserContext);
  return user;
}
