
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import Link from "mm-router/components/Link";
import EmailLink from "mm-router/components/EmailLink";

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h1" components={components}>{`Privacy Policy`}</MDXTag>
<MDXTag name="p" components={components}>{`Last update: January 29, 2019`}</MDXTag>
<MDXTag name="p" components={components}>{`We `}<span children={props.weUs}></span>{` would like to explain how information about you is collected, used, and shared when you use
our `}<span children={props.theServices}></span>{` or when you otherwise interact
with us or receive a communication from us.`}</MDXTag>
<MDXTag name="p" components={components}>{`By using or accessing the Services in any manner, you acknowledge that
you accept the practices and policies outlined in this Privacy Policy,
and you hereby consent that we will collect, use, and share your
information in the following ways. Remember that your use of the Services is at all times subject to the `}<Link to="/terms">{`Terms of Use`}</Link>{`, which incorporates this Privacy
Policy. Any terms we use in this Policy without defining them have the
definitions given to them in the Terms of Use.`}</MDXTag>
<MDXTag name="h2" components={components}>{`What are the highlights?`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">
<MDXTag name="p" components={components} parentName="li">{`We maintain one or more databases of the information users provide to us as part of using our Services.`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ul">
<MDXTag name="p" components={components} parentName="li">{`We integrate with third parties as needed to provide features such
as Social Logins and Payments. Those third parties may provide or make
use of user data as needed to fulfill their role.`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ul">
<MDXTag name="p" components={components} parentName="li">{`We use industry standard services and best practices for security
logs, analytics, and crash reporting. Those services may make use of
user data as needed to fulfill their role.`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ul">
<MDXTag name="p" components={components} parentName="li">{`We will make a best effort to either provide or delete/unlink user data as requested either via the Services or email.`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ul">
<MDXTag name="p" components={components} parentName="li">{`Children under 13 are prohibited from using our Services.`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ul">
<MDXTag name="p" components={components} parentName="li">{`We may want to contact you directly via email or other contact information you provide, subject to your contact preferences.`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="h2" components={components}>{`What does this Privacy Policy cover?`}</MDXTag>
<MDXTag name="p" components={components}>{`This Privacy Policy covers our treatment of personally identifiable information ("Personal Information") that we gather when you are accessing or using our Services, but not to the practices of companies we don’t own or control, or people that we don’t manage. We gather various types of Personal Information from our users, as explained in more detail below, and we use this Personal Information internally in connection with our Services, including to personalize, provide, and improve our services, to allow you to set up a user account and profile, to contact you and allow other users to contact you, to fulfill your requests for certain products and services, and to analyze how you use the Services. In certain cases, we may also share some Personal Information with third parties, but only as described below.`}</MDXTag>
<MDXTag name="p" components={components}>{`As noted in the Terms of Use, we do not knowingly collect or solicit personal information from anyone under the age of 13. If you are under 13, please do not attempt to register for the Services or send any personal information about yourself to us. If we learn that we have collected personal information from a child under age 13, we will delete that information as quickly as possible. If you believe that a child under 13 may have provided us personal information, please contact us at `}<EmailLink email={props.contactEmail}/>{`.`}</MDXTag>
<MDXTag name="h2" components={components}>{`What We Collect`}</MDXTag>
<MDXTag name="h3" components={components}>{`Information You Provide to Us`}</MDXTag>
<MDXTag name="p" components={components}>{`We collect information you provide to us directly when you use the Services. This includes:`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`Account information.`}</MDXTag>{`  To create an account, you must provide a username and password. Your username is public, and it doesn’t have to be related to your real name. You may also provide an email address. We also store your user account preferences and settings.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`Third party account information.`}</MDXTag>{` If you provide your third-party account credentials (for example, your log-in credentials for Twitter or other third party sites) to us or otherwise sign in to the Services through a third party site or service, you understand some content and/or information in those accounts (“Third Party Account Information”) may be transmitted into your account with us if you authorize such transmissions], and that Third Party Account Information transmitted to our Services is covered by this Privacy Policy. Certain information may be required to register with us or to take advantage of some of our features.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`Content you submit.`}</MDXTag>{`  We collect the content you submit to the Services. Your content may include text, links, images, gifs, and videos.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`Actions you take.`}</MDXTag>{`  We collect information about the actions you take when using the Services. This includes your interactions with content, like voting, saving, hiding, and reporting. It also includes your interactions with other users, such as following, friending, and blocking.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`Transactional information.`}</MDXTag>{`  If you purchase products or services from us, we will collect certain information from you, including your name, address, email address, and information about the product or service you are purchasing. Payments are processed by third-party payment processors (e.g., Stripe and PayPal), so please refer to the applicable processor’s terms and privacy policy for more information about how payment information is processed and stored.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`Other information.`}</MDXTag>{`  You may choose to provide other information directly to us. For example, we may collect information when you fill out a form, participate in activities or promotions, request customer support or otherwise communicate with us.`}</MDXTag>
<MDXTag name="h3" components={components}>{`Information We Collect Automatically`}</MDXTag>
<MDXTag name="p" components={components}>{`When you access or use our Services, we may also automatically collect information about you. This includes:`}</MDXTag>
<MDXTag name="p" components={components}>{`Log and usage data.  We may log information when you access and use the Services. This may include your IP address, user-agent string, browser type, operating system, referral URLs, device information (e.g., device IDs), pages visited, links clicked, the requested URL, hardware settings, and search terms.`}</MDXTag>
<MDXTag name="p" components={components}>{`Information collected from cookies and similar technologies.  We may receive information from cookies, which are pieces of data your browser stores and sends back to us when making requests, and similar technologies. We use this information to improve your experience, understand user activity, and improve the quality of our Services. You may be able to change the preferences on your browser or device to prevent or limit your device’s acceptance of cookies, but this may prevent you from taking advantage of some of our features. Also if you click on a link to a third party website or service, such third party may also transmit cookies to you. Again, this Privacy Policy does not cover the use of cookies by any third parties, and we aren’t responsible for their privacy policies and practices.`}</MDXTag>
<MDXTag name="h2" components={components}>{`How We Use Information About You`}</MDXTag>
<MDXTag name="p" components={components}>{`We use information about you to:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Provide, maintain, and improve the Services`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Research and develop new services`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Help protect the safety of `}<span children={props.name}></span>{` and our users, which includes blocking suspected spammers, addressing abuse, and enforcing any community guidelines or other policies`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Send you technical notices, updates, security alerts, invoices and other support and administrative messages`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Provide customer service`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Communicate with you about products, services, offers, promotions, and events, and provide other news and information we think will be of interest to you`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Monitor and analyze trends, usage, and activities in connection with our Services`}</MDXTag>
</MDXTag>
<MDXTag name="h2" components={components}>{`Your Choices`}</MDXTag>
<MDXTag name="p" components={components}>{`You can always opt not to disclose information to us, but keep in mind some information may be needed to register with us or to take advantage of some of our features.`}</MDXTag>
<MDXTag name="p" components={components}>{`You may be able to add, update, or delete information as explained above. When you update information, however, we may maintain a copy of the unrevised information in our records. You may request deletion of your account by contacting us at `}<EmailLink email={props.contactEmail}/>{` and we will disassociate our email address and third party accounts (e.g. Twitter) from any content or other information provided to us. Some information may remain in our records after your deletion of such information from your account. We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that would identify you personally.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Other Information`}</MDXTag>
<MDXTag name="h3" components={components}>{`Information Security`}</MDXTag>
<MDXTag name="p" components={components}>{`Your account is protected by a password for your privacy and security. If you access your account via a third party site or service, you may have additional or different sign-on protections via that third party site or service. You must prevent unauthorized access to your account and Personal Information by selecting and protecting your password and/or other sign-on mechanism appropriately and limiting access to your computer or device and browser by signing off after you have finished accessing your account. We endeavor to protect the privacy of your account and other Personal Information we hold in our records, but unfortunately, we cannot guarantee complete security. Unauthorized entry or use, hardware or software failure, and other factors, may compromise the security of user information at any time.`}</MDXTag>
<MDXTag name="h3" components={components}>{`Data Retention`}</MDXTag>
<MDXTag name="p" components={components}>{`We store the information we collect for as long as it is necessary for the purpose(s) for which we originally collected it. We may retain certain information for legitimate business purposes or as required by law.`}</MDXTag>
<MDXTag name="h3" components={components}>{`Children`}</MDXTag>
<MDXTag name="p" components={components}>{`Children under the age of 13 are not allowed to create an account or otherwise use the Services. Additionally, you must be over the age required by the laws of your country to create an account or otherwise use the Services.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Disclosure of Your Personal Data and Other Information`}</MDXTag>
<MDXTag name="p" components={components}>{`We may share your Personal Information with third parties as described in this section:`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`Information that’s no longer personally identifiable.`}</MDXTag>{` We may anonymize your Personal Information so that you are not individually identified, and provide that information to our partners. We may also provide aggregate usage information to our partners, who may use such information to understand how often and in what ways people use our Services, so that they, too, can provide you with an optimal online experience.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`Affiliated Businesses`}</MDXTag>{` In certain situations, businesses or third party websites we’re affiliated with may sell or provide products or services to you through or in connection with the Services (either alone or jointly with us). You can recognize when an affiliated business is associated with such a transaction or service, and we will share your Personal Information with that affiliated business only to the extent that it is related to such transaction or service. One such service may include the ability for you to automatically transmit Third Party Account Information to your Services profile or to automatically transmit information in your Services profile to your third party account; for example, you may sign into your `}<span children={props.name}></span>{` account using your Twitter account. We have no control over the policies and practices of third party websites or businesses as to privacy or anything else, so if you choose to take part in any transaction or service relating to an affiliated website or business, please review all such business’ or websites’ policies.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`Our Agents`}</MDXTag>{` We employ other companies and people to perform tasks on our behalf and need to share your information with them to provide products or services to you. Unless we tell you differently, our agents do not have any right to use the Personal Information we share with them beyond what is necessary to assist us.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`User Profiles and Submissions`}</MDXTag>{` Certain user profile information, including your name, location, and any video or image content that such user has uploaded to the Services, may be displayed to other users to facilitate user interaction within the Services or address your request for our services. Please remember that any content you upload to your public user profile, along with any Personal Information or content that you voluntarily disclose online in a manner other users can view (on discussion boards, in messages and chat areas, etc.) becomes publicly available, and can be collected and used by anyone. Your user name may also be displayed to other users if and when you send messages or comments or upload images or videos through the Services and other users can contact you through messages and comments. Additionally, if you sign into the Services through a third party social networking site or service, your list of “friends” from that site or service may be automatically imported to the Services, and such “friends,” if they are also registered users of the Services, may be able to access certain non-public information you have entered in your Services user profile. Again, we do not control the policies and practices of any other third party site or service.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`Business Transfers`}</MDXTag>{` We may choose to buy or sell assets. In these types of transactions, customer information is typically one of the business assets that would be transferred. Also, if we (or our assets) are acquired, or if we go out of business, enter bankruptcy, or go through some other change of control, Personal Information could be one of the assets transferred to or acquired by a third party.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`Protection`}</MDXTag>{` We reserve the right to access, read, preserve, and disclose any information that we reasonably believe is necessary to comply with law or court order; enforce or apply our `}<Link to="/terms">{`Terms of Use`}</Link>{` and other agreements; or protect the rights, property, or safety of `}<span children={props.name}></span>{`, our employees, our users, or others.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Changes to this Privacy Policy`}</MDXTag>
<MDXTag name="p" components={components}>{`The Site and our operations may change from time to time. As a result, at times it may be necessary for `}<span children={props.name}></span>{` to make changes to this Privacy Policy. `}<span children={props.name}></span>{` reserves the right to update or modify this Privacy Policy at any time and from time to time without prior notice. Please review this policy periodically, and especially before you provide any Personal Data. This Privacy Policy was last updated on the date indicated above. Your continued use of the Site after any changes or revisions to this Privacy Policy shall indicate your agreement with the terms of such revised Privacy Policy.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Contact Us`}</MDXTag>
<MDXTag name="p" components={components}>{`If you have any questions or concerns regarding our privacy policies, please send us a detailed message to `}<EmailLink email={props.contactEmail}/>{`, and we will try to resolve your concerns.`}</MDXTag>
           </MDXTag>
  }
}
  