import React from "react";
import Link from "mm-router/components/Link";

export default function EmailLink(
  props: Omit<React.ComponentProps<typeof Link>, "to"> & { email: string }
) {
  let { email, children, ...rest } = props;
  children = children || email;
  return (
    <Link {...rest} to={`mailto:${email}`}>
      {children}
    </Link>
  );
}
