import firebase from "firebase";
import _ from "lodash";
import Badge from "mm-threads/components/Badge";
import Cell from "mm-threads/components/Cell";
import Command from "mm-threads/components/Command";
import Title from "mm-threads/components/Title";
import React from "react";
import { PROVIDERS, useCurrentUser } from "../components/UserBadges";
import { User } from "../types";

function Identities() {
  const user = useCurrentUser();

  let children = [];

  for (let [id, provider] of _.entries(PROVIDERS)) {
    const identity = user[id as keyof User];
    if (identity) {
      children.push(
        <Cell key={`cell-has-${id}`} indent={2}>
          <Title key={`title-${id}`}>{id}</Title>
          <Badge
            key={`badge-${id}`}
            icon={`fa5 ${id}`}
            label={id}
            value={identity.displayName}
          />
          <Command
            key={`signout-${id}`}
            icon={`cloud-off`}
            label={`Sign Out`}
            onCommand={async () => {
              // currentUser.unlink("github.com");
              await firebase.auth().signOut();
              // props.onUpdate({});
            }}
          />
        </Cell>
      );
    } else {
      children.push(
        <Cell key={`cell-not-has-${id}`} indent={2}>
          <Title key={`title-${id}`}>{id}</Title>
          <Command
            key={`add-${id}`}
            icon={`fa5 ${id}`}
            label={`Sign in with ${id}`}
            onCommand={async () => {
              await firebase.auth().signInWithPopup(provider);
            }}
          />
        </Cell>
      );
    }
  }
  return <>{children}</>;
}

export default function Profile() {
  return (
    <>
      <Cell indent={1}>
        <Title>Accounts</Title>
      </Cell>
      <Identities />
    </>
  );
}
