import { FetchResult } from "apollo-link";
import _ from "lodash";
import { firstLetterUpper } from "mm-core/strings";
import gql from "graphql-tag";
import { core } from "nexus";
import { useApolloClient } from "react-apollo-hooks";
import { useCollection, useModel } from "mm-firebase-client/database/hooks";
import { useState } from "react";

type MutationArgsFor<T extends string> = core.GetGen3<
  "argTypes",
  "Mutation",
  T
>;
type MutationResultFor<T extends string> = core.GetGen3<
  "fieldTypes",
  "Mutation",
  T
>;

type RootType<T extends string> = core.GetGen2<"rootTypes", T>;

type MutationResult<T extends string> = { value: MutationResultFor<T> };
type MutationFetchResult<T extends string> = FetchResult<MutationResult<T>>;
type InputArgsFor<T extends string> = MutationArgsFor<T>["input"];

export function useMutationFunction<T extends string>(
  name: T
): (args: InputArgsFor<T>) => Promise<MutationFetchResult<T>> {
  const client = useApolloClient();

  return async (args: InputArgsFor<T>) => {
    const mutation = gql`
      mutation ${name}($input: ${firstLetterUpper(name)}Input!) {
        value: ${name}(input: $input)
      }
    `;

    return await client.mutate<MutationResult<T>>({
      mutation,
      variables: { input: args }
    });
  };
}

export function useEntityCollection<T extends string>(
  name: T,
  parent?: string
): RootType<T>[] {
  let [entities] = useCollection<RootType<T>>(
    parent ? ["entities", "children", parent] : ["entities", "root"]
  );

  entities = _.orderBy(entities, ["created"], ["desc"]);
  return entities;
}

export function useEntityModel<T extends string>(
  name: T,
  id: string,
  parent?: string
) {
  return useModel<RootType<T>>(
    parent ? ["entities", "children", parent, id] : ["entities", "root", id]
  );
}

export type EntityFormMode = "pending" | "submitting" | "submitted";

export type EntityForm<T extends string> = {
  name: T;
  mode: EntityFormMode;
  pending: InputArgsFor<T>;
  setPending: (pending: Partial<InputArgsFor<T>>) => unknown;
  init: () => unknown;
  cancel: () => unknown;
  toggle: () => unknown;
  submit: () => Promise<MutationFetchResult<T>>;
};

export function useEntityForm<T extends string>(
  name: T,
  initial: () => InputArgsFor<T>,
  initializeOnStart = false
) {
  const [pending, setPendingRaw] = useState<InputArgsFor<T> | null>(
    initializeOnStart ? initial() : null
  );

  const [mode, setMode] = useState<EntityFormMode>("pending");

  const _submit = useMutationFunction(name);

  function init() {
    setPendingRaw(initial());
    setMode("pending");
  }

  function cancel() {
    setPendingRaw(null);
    setMode("pending");
  }

  function toggle() {
    if (pending != null) {
      cancel();
    } else {
      init();
    }
  }

  function setPending(update: Partial<InputArgsFor<T>> | null) {
    if (update != null && pending != null) {
      //@ts-ignore
      setPendingRaw({ ...pending, ...update });
    } else {
      setPendingRaw(null);
    }
  }

  async function submit() {
    if (pending == null) {
      throw new Error("Bad Submit");
    }
    try {
      setMode("submitting");
      const result = await _submit(pending);
      setPendingRaw(null);
      setMode("submitted");
      return result;
    } catch (error) {
      setMode("pending");
    }
  }

  return { name, pending, setPending, init, cancel, toggle, submit, mode };
}
