import _ from "lodash";
import * as ds from "mm-ds";
import ThreadStore, { Context } from "./stores/ThreadStore";

type FnVoid = (arg0: Context) => void;
type FnStore = () => ThreadStore;

const _all: Array<FnStore> = [];

export function thread(fn: FnVoid): FnStore {
  const result = _.memoize(() => {
    const ctx: Context = {};
    fn(ctx);
    return ThreadStore.create(ctx);
  });
  _all.push(result);
  return result;
}

export const useThreads = ds.wrap(
  (): Array<ThreadStore> => {
    return _all.map(fn => fn());
  }
);
