import React from "react";
import { ThemeType, ColorType } from "../themes/build";
import themeDefault from "../themes/default";
import ThemeLoad from "./Load";

export { ColorType, ThemeType };

const { useContext } = React;

export const ThemeContext = React.createContext(themeDefault);

export function useTheme(): [ThemeType] {
  const theme = useContext(ThemeContext);
  return [theme];
}

export default function ThemeProvider(props: {
  theme: ThemeType;
  children: React.ReactNode;
}) {
  return (
    <ThemeContext.Provider value={props.theme}>
      {props.children}
    </ThemeContext.Provider>
  );
}

export { ThemeLoad };
