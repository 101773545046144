import Interweave from "interweave";
import React from "react";
import { block } from "./Cell";
import Content from "./Content";

function Html(props: { children: string }) {
  return (
    <Content>
      <Interweave content={props.children} />
    </Content>
  );
}

export default block(Html);
