import chroma from "chroma-js";
import verticalRhythm from "compass-vertical-rhythm";
import gray from "gray-percentage";
import Typography from "typography";
import { MOBILE_MEDIA_QUERY } from "typography-breakpoint-constants";

export type ThemeType = ReturnType<typeof build>;

// https://coolors.co/1f1f36-444760-8c808b-b79e98-dcd4d0

function scale(base: string) {
  let lpct = 0.15;
  let dpct = 0.15;

  // if (isDark(base)) {
  //   dpct = 0.15;
  // }

  const light = chroma(base)
    .brighten(lpct)
    .hex();
  const lighter = chroma(light)
    .brighten(lpct)
    .hex();
  const lightest = chroma(lighter)
    .brighten(lpct)
    .hex();
  const dark = chroma(base)
    .darken(dpct)
    .hex();
  const darker = chroma(dark)
    .darken(dpct)
    .hex();
  const darkest = chroma(darker)
    .darken(dpct)
    .hex();

  let extra = isDark(base)
    ? {
      dim: [dark, darker, darkest],
      bold: [light, lighter, lightest]
    }
    : {
      dim: [light, lighter, lightest],
      bold: [dark, darker, darkest]
    };

  return {
    ...extra,
    lightest,
    lighter,
    light,
    base,
    dark,
    darker,
    darkest
  };
}

export function isLight(color: string) {
  return chroma(color).luminance() > 0.6;
}

export function isDark(color: string) {
  return !isLight(color);
}

export function color(base: string, text?: string) {
  let contrast = text;

  if (!contrast) {
    if (isLight(base)) {
      // contrast = "black"
      contrast = chroma(base)
        .luminance(0.1)
        .hex();
    } else {
      // contrast = "white"
      contrast = chroma(base)
        .luminance(0.9)
        .hex();
    }
  }

  return { ...scale(base), text: scale(contrast) };
}

export type ColorScaleType = ReturnType<typeof scale>;
export type ColorType = ReturnType<typeof color>;

// 010009-726BB3-23222E-A7B6CB-CF9683
// export const content = color("#f1f1f1", "#495057");
// export const primary = color("#1c7ed6", "#e7f5ff");
// export const secondary = color("#ff922b", "#fff4e6");

// export const headerFontFamily = ["Roboto Slab", "sans-serif"];
// export const bodyFontFamily = ["Roboto", "serif"];
// export const googleFonts = [
//   {
//     name: "Roboto Slab",
//     styles: ["700"]
//   },
//   {
//     name: "Roboto",
//     styles: ["400", "400i", "700"]
//   }
// ];

// export const headerFontFamily = ["Open Sans", "sans-serif"];
// export const bodyFontFamily = ["Open Sans", "serif"];
// export const googleFonts = [
//   {
//     name: "Open Sans",
//     styles: ["400", "400i", "700"]
//   }
// ];

export default function build(conf: {
  content: ColorType;
  primary: ColorType;
  secondary: ColorType;
  headerFontFamily: string[];
  bodyFontFamily: string[];
  googleFonts: { name: string; styles: string[] }[];
}) {
  let linkBase = conf.secondary.base;
  let linkColor: string;
  let linkColorHover: string;

  if (isDark(conf.content.base)) {
    linkColor = chroma(linkBase)
      .saturate(3)
      .luminance(0.55)
      .hex();
    linkColorHover = chroma(linkColor)
      .brighten()
      .brighten()
      .hex();
  } else {
    linkColor = chroma(linkBase)
      .saturate(3)
      .luminance(0.45)
      .hex();
    linkColorHover = chroma(linkColor)
      .darken()
      .darken()
      .hex();
  }

  const typotheme = {
    ...conf,
    title: "Core",
    baseFontSize: "16px",
    baseLineHeight: 1.45,
    headerLineHeight: 1.1,
    scaleRatio: 1.3,
    // googleFonts,
    // headerFontFamily,
    // bodyFontFamily,
    includeNormalize: true,
    headerColor: "inherit",
    bodyColor: conf.content.base,
    headerWeight: 600,
    bodyWeight: 400,
    boldWeight: 600,
    overrideStyles: ({ adjustFontSizeTo, scale, rhythm }, options) => {
      const vr = verticalRhythm({
        baseFontSize: "16px",
        baseLineHeight: 1.2
      });
      return {
        a: {
          color: linkColor,
          textDecoration: "none"
        },
        "a:hover": {
          color: linkColorHover
        },
        "a:hover,a:active": {
          textShadow: "none",
          backgroundImage: "none"
        },
        "h1,h2,h3,h4,h5,h6": {
          marginTop: rhythm(0.2),
          marginBottom: rhythm(0.8)
        },
        ".cell-container h1,.cell-container h2,.cell-container h3,.cell-container h4,.cell-container h5,.cell-container h6,.cell-container p": {
          marginTop: rhythm(0.2),
          marginBottom: rhythm(0.25)
        },
        ".cell-container ul": {
          marginBottom: 0
        },
        // children ol, ul
        "li>ol,li>ul": {
          marginLeft: "20px",
          marginBottom: 0
        },
        // Blockquote styles.
        blockquote: {
          ...scale(1 / 5),
          borderLeft: `${rhythm(6 / 16)} solid ${linkColor}`,
          color: gray(35),
          paddingLeft: rhythm(10 / 16),
          fontStyle: "italic",
          marginLeft: 0,
          marginRight: 0
        },
        "blockquote > :last-child": {
          marginBottom: 0
        },
        "blockquote cite": {
          ...adjustFontSizeTo(options.baseFontSize),
          color: options.bodyColor,
          fontStyle: "normal",
          fontWeight: options.bodyWeight
        },
        "blockquote cite:before": {
          content: '"— "'
        },
        [MOBILE_MEDIA_QUERY]: {
          html: {
            ...vr.establishBaseline()
          },
          blockquote: {
            borderLeft: `${rhythm(3 / 16)} solid ${linkColor}`,
            color: gray(41),
            paddingLeft: rhythm(9 / 16),
            fontStyle: "italic",
            marginLeft: rhythm(-3 / 4),
            marginRight: 0
          }
        }
      };
    }
  };

  const typography = new Typography(typotheme);
  return { ...conf, typography };
}
