import React from "react";
import Row from "./Row";
import Color from "mm-themes/components/Color";
import { useTheme } from "mm-themes/components/Theme";
import Loading from "./Loading";
import { EntityForm } from "../hooks";

export function block<T extends React.ComponentType<any>>(
  component: T,
  sameRow?: (other: any) => boolean
): T {
  if (sameRow) {
    //@ts-ignore
    component.sameRow = sameRow;
  }
  return component;
}

function Cell(props: {
  indent?: number;
  form?: undefined | EntityForm<any>;
  selected?: boolean;
  bold?: boolean;
  children?: React.ReactNode;
}) {
  const [theme] = useTheme();
  const indent = props.indent || 0;
  const children = React.Children.toArray(props.children || []);
  const color = props.bold ? theme.primary : theme.content;
  const isLoading = props.form && props.form.mode === "submitting";

  let content = [];

  if (children && children.length > 0) {
    let [current, ...rest] = children;
    let pending = [current];
    let index = 0;
    for (let child of rest) {
      let previous = pending[pending.length - 1];
      //@ts-ignore
      let sameRow =
        (child as any).type.sameRow != null &&
        (child as any).type.sameRow(previous && (previous as any).type);

      if (sameRow) {
        pending.push(child);
      } else {
        content.push(<Row key={`row-${index}`}>{pending}</Row>);
        pending = [child];
      }
      index += 1;
    }

    content.push(<Row key={`row-${index}`}>{pending}</Row>);
    pending = [];
  }

  if (props.form && props.form.mode === "submitted") {
    return <></>;
  }

  let result = (
    <div
      className="cell-container"
      style={{
        flex: 1,
        position: "relative",
        padding: 10,
        marginLeft: 10 * indent,
        backgroundColor: color.base,
        borderBottomStyle: "solid",
        borderBottomWidth: 2,
        borderBottomColor: color.darker
      }}
    >
      <Color color={color}>
        <div style={isLoading ? { visibility: "hidden" } : null}>{content}</div>
        {isLoading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Loading key="loading" />
          </div>
        )}
      </Color>
    </div>
  );

  // if (indent > 0) {
  //   result = (
  //     <View style={{ flex: 1, backgroundColor: color.dim[1] }}>{result}</View>
  //   );
  // }
  return result;
}

export default Cell;
