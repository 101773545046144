export type Context<In, Out> = {
  (value: In, fn?: Function): void;
  name: string;
  current: Out;
};

let noop = () => { };

export function create1<T>(label: string, initial: T): Context<T, T> {
  return create(label, initial, v => {
    return { current: v };
  });
}

export function create<In, Out>(
  label: string,
  initial: In,
  apply: (
    v: In,
    before?: Out
  ) => { current: Out; children?: (fn: Function) => void }
): Context<In, Out> {
  const result = function(value: In, fn?: Function): void {
    const before = result.current;
    try {
      const { current, children } = apply(value, before);
      result.current = current;
      if (fn) {
        if (children) {
          children(fn);
        } else {
          fn();
        }
      }
    } finally {
      if (fn) {
        result.current = before;
      }
    }
  };

  result.label = label;
  result.current = apply(initial).current;
  return result;
}
