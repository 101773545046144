import { useColor } from "mm-themes/components/Color";
import React from "react";
import { TextInput } from "react-native";
import { block } from "./Cell";

function Title(props: { children: React.ReactNode; level?: number }) {
  const color = useColor();
  const level = props.level != null ? props.level : 1;

  const Header = `h${level}` as any;

  return (
    <Header
      style={{
        color: color.text.base
      }}
    >
      {props.children}
    </Header>
  );
}

//:: declare Title.Editor =
function Editor(props: {
  children: string;
  onUpdate: (value: string) => void;
}) {
  const color = useColor();
  return (
    <TextInput
      style={{
        flex: 1,
        color: color.text.base,
        backgroundColor: color.lightest,
        paddingHorizontal: 10,
        paddingVertical: 5,
        marginBottom: 5,
        fontSize: 16
      }}
      autoFocus
      selectTextOnFocus
      value={props.children}
      onChangeText={props.onUpdate}
    />
  );
}

Title.Editor = block(Editor);

export default block(Title);
