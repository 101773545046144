import _ from "lodash";
import { useStories, useStory, Story, Comment } from "mm-hacker-news";
import Badge from "mm-threads/components/Badge";
import Cell from "mm-threads/components/Cell";
import Command from "mm-threads/components/Command";
import Html from "mm-threads/components/Html";
import Title from "mm-threads/components/Title";
import { thread } from "mm-threads/registry";
import React from "react";

export function HNLatest({ match }: { match: any }) {
  const stories = useStories();

  return (
    <>
      {stories.map(story => {
        return (
          <Cell key={`story-${story.id}`} indent={0}>
            <Title level={3}>{story.title}</Title>
            <Badge icon="show-chart" label="score" value={story.points} />
            <Badge icon="person" label="creator" value={story.user} />
            <Badge icon="access-time" label="created" value={story.time_ago} />
            <Badge
              icon="comment"
              label="comments"
              value={story.comments_count}
            />
            <Badge icon="public" label="domain" value={story.domain} />
            <Command
              icon="comment"
              label="Comments"
              to={`${match.path}/${story.id}`}
            />
            <Command icon="open-in-browser" label="Link" to={story.url} />
          </Cell>
        );
      })}
    </>
  );
}

export function HNThread({ match }: { match: any }) {
  const id = match.params.id || "18421755";
  const story: null | Story = useStory(id);

  if (!story) {
    throw new Error("not found");
  }

  const mapComment = (comment: Comment) => {
    return [
      <Cell key={`comment-${comment.id}`} indent={comment.level + 1}>
        <Badge icon="person" label="creator" value={comment.user} />
        <Badge icon="access-time" label="created" value={comment.time_ago} />
        <Html>{comment.content}</Html>
        <Command icon="reply" label="Reply" />
        <Command icon="thumb-up" label="Upvote" />
        <Command icon="thumb-down" label="Downvote" />
        <Command icon="flag" label="Flag" />
      </Cell>,
      ...(comment.comments || []).map(mapComment)
    ];
  };

  const comments = _.flattenDeep(story.comments.map(mapComment));

  return (
    <>
      <Cell key="header">
        <Title>{story.title}</Title>
        <Badge icon="show-chart" label="score" value={story.points} />
        <Badge icon="person" label="creator" value={story.user} />
        <Badge icon="access-time" label="created" value={story.time_ago} />
        <Badge icon="comment" label="comments" value={story.comments_count} />
        <Badge icon="public" label="domain" value={story.domain} />
        <Command icon="open-in-browser" label="Open" to={story.url} />
        <Command icon="reply" label="Reply" />
        <Command icon="thumb-up" label="Upvote" />
        <Command icon="thumb-down" label="Downvote" />
        <Command icon="flag" label="Flag" />
      </Cell>
      {comments}
    </>
  );
}

thread(t => {
  t.path = "hn";
  t.label = "Hacker News";
  t.icon = "fa5 user-secret";
  t.renderIndex = HNLatest;
  t.renderItem = HNThread;
});
