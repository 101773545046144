import _ from "lodash";
import { mixin } from "./objects";
import { computed, runInAction } from "mobx";

const GLOBAL = global as any;

export function isReactComponent(target: any): boolean {
  let result = GLOBAL.React && target instanceof GLOBAL.React.Component;
  if (!result) {
    result = target.__proto__ != null && !!target.__proto__.isReactComponent;
  }
  return result;
}

export default class Dependencies {
  static registerForMount(target: any, prop: string) {
    let mountKeys = target["__deps_mount_keys"];

    if (!mountKeys) {
      mountKeys = target["__deps_mount_keys"] = [];
      Dependencies.mixinMount(target);
    }
    mountKeys.push(prop);
  }

  static register(target: any) {
    const registered = target["__deps_registered"];
    if (!registered) {
      Object.defineProperty(target, "deps", {
        get: function() {
          if (!this.__deps) {
            this.__deps = new Dependencies(this);
          }
          return this.__deps;
        },
        enumerable: true,
        configurable: true
      });

      Dependencies.mixinUnmount(target);
    }
  }

  static unmountValue(
    value: any,
    label: string,
    asyncUnmount: boolean = false
  ) {
    if (value && value.unmount) {
      if (asyncUnmount) {
        setTimeout(() => {
          runInAction(`Dependencies.unmountValue - ${label}`, () => {
            value.unmount();
          });
        }, 1);
      } else {
        runInAction(`Dependencies.unmountValue - ${label}`, () => {
          value.unmount();
        });
      }
    }
  }

  static mountValue(value: any, label: string) {
    if (value && value.mount) {
      runInAction(`Dependencies.mountValue - ${label}`, () => {
        value.mount();
      });
    }
  }

  constructor(instance: Object) {
    this.instance = instance;
  }

  get keys(): Array<string> {
    return _.keys(this.values);
  }

  toMount: Array<string> = [];
  instance: Object;
  values: { [key: string]: any } = {};

  static mixinMount(target: any) {
    let mountName = isReactComponent(target)
      ? "componentDidMount"
      : target.didMount
        ? "didMount"
        : "mount";

    mixin(target, mountName, function() {
      // console.log(
      //   "MOUNT MIXIN CALLED FOR TARGET ",
      //   target.constructor.name,
      //   mountName
      // );
      const deps: Dependencies = this.deps;
      if (deps) {
        deps.mount();
      }
    });
  }

  static mixinUnmount(target: any) {
    const unmountName = isReactComponent(target)
      ? "componentWillUnmount"
      : "willUnmount";

    mixin(target, unmountName, function() {
      // console.log(
      //   "UNMOUNT MIXIN CALLED FOR TARGET ",
      //   target.constructor.name,
      //   unmountName
      // );
      const deps: Dependencies = this.deps;
      if (deps) {
        deps.unmount();
      }
    });
  }

  provide(
    instance: any,
    prop: string,
    descriptor: any,
    mountAndChange: boolean = false
  ) {
    let config;
    if (!this.values.hasOwnProperty(prop)) {
      config = this.values[prop] = {
        computation: computed(() => descriptor.get.call(instance), {
          context: instance,
          // compareStructural: true, //nice if multiple nulls returned
          name: `dep_${prop}`
        })
      };

      //keep alive so we don't loose the value when no observers
      config.dispose = config.computation.observe(v => {
        // console.log("got a new value for prop ", prop, v);

        //This makes sure we mount a value even if it starts off null
        //TODO: Find a cleaner approach
        if (mountAndChange && config && config.value !== v) {
          this.provide(instance, prop, descriptor);
        }
      });
    } else {
      config = this.values[prop];
    }

    const result = config.computation.get();

    if (config.value !== result) {
      Dependencies.unmountValue(config.value, `@provided ${prop}`, true);
      config.value = result;

      Dependencies.mountValue(config.value, `@provided ${prop}`);
    }
    return config.value;
  }

  getOrCreate(instance: any, prop: string, descriptor: any, label: string) {
    let config = this.values[prop];
    if (!config) {
      const method = descriptor.get || descriptor.initializer;
      config = this.values[prop] = {
        value: method.call(instance)
      };
      Dependencies.mountValue(config.value, `${label} ${prop}`);
    }
    return config.value;
  }

  mount = () => {
    const toMount: Array<string> = this.instance["__deps_mount_keys"] || [];
    // console.log("calling mount on Dependencies with toMount ", toMount);
    for (let prop of toMount) {
      // eslint-disable-next-line no-unused-vars
      const _ = this.instance[prop]; //trigger create/mount
      // console.log("mounted Dependencies dep ", prop, value);
    }
  };

  unmount() {
    // console.log("calling unmount on Dependencies", this.instance);
    for (let key in this.values) {
      // console.log("calling unmount on Dependencies key ", key);
      Dependencies.unmountValue(this.values[key].value, `deps.unmount ${key}`);

      if (this.values[key].dispose) {
        // console.log("calling dispose on key ", key);
        this.values[key].dispose();
      }
    }
    this.values = {};
  }
}
