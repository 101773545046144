import { useTheme } from "mm-themes/components/Theme";
import Cell from "mm-threads/components/Cell";
import Command from "mm-threads/components/Command";
import Content from "mm-threads/components/Content";
import Loading from "mm-threads/components/Loading";
import Title from "mm-threads/components/Title";
import React, { Suspense, useState } from "react";
import { useEntityForm } from "mm-threads/hooks";

function Expand(props: {
  indent?: number;
  id: string;
  action?: string;
  children: React.ReactNode;
  expanded?: boolean;
}) {
  const [expanded, setExpanded] = useState(!!props.expanded);
  const indent = props.indent || 0;
  const action = props.action || "Get Started";
  const [theme] = useTheme();

  return (
    <>
      <Cell indent={indent}>
        <Command
          icon={expanded ? "expand-less" : "expand-more"}
          label={expanded ? "Hide Examples" : "See Examples"}
          onCommand={() => setExpanded(!expanded)}
        />
      </Cell>
      <div key="wrapper" style={{ display: expanded ? "block" : "none" }}>
        {props.children}
      </div>
    </>
  );
}

export default function Home() {
  const [theme] = useTheme();

  const form = useEntityForm(
    "addEmail",
    () => ({
      email: ""
    }),
    true
  );

  return (
    <>
      <Cell indent={0}>
        <Title>Beta</Title>
        <Content>
          <h3 style={{ marginBottom: 15 }}>
            Thanks so much for your interest!
          </h3>
        </Content>
      </Cell>
      <Cell indent={1} form={form}>
        <Content>
          Our first limited beta is starting soon. Let us know if you're
          interested in updates and early access.
        </Content>
        <Title level={6}>Your Email:</Title>
        <Title.Editor onUpdate={email => form.setPending({ email })}>
          {(form.pending && form.pending.email) || ""}
        </Title.Editor>
        <Command
          icon="send"
          label="I'm Interested! Send Me Updates"
          color={theme.primary}
          onCommand={form.submit}
        />
      </Cell>
    </>
  );
}
