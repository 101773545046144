import UserBadges from "mm-informatted/components/UserBadges";
import Cell from "mm-threads/components/Cell";
import Command from "mm-threads/components/Command";
import Content from "mm-threads/components/Content";
import Title from "mm-threads/components/Title";
import {
  EntityForm,
  useEntityCollection,
  useEntityForm,
  useEntityModel,
  useMutationFunction
} from "mm-threads/hooks";
import { thread } from "mm-threads/registry";
import React from "react";
import { useCurrentUser } from "../components/UserBadges";
import { Board, Reply } from "../types";

const ContentEditor = React.lazy(() =>
  import("../../mm-threads/components/ContentEditor")
);

export function NewBoard(props: {
  bold?: boolean;
  indent?: number;
  form: EntityForm<"createBoard" | "createReply">;
  onCreate?: (id: string) => void;
}) {
  const user = useCurrentUser();
  const { bold, indent, form } = props;

  return (
    form.pending != null && (
      <Cell
        key="editor"
        form={form}
        indent={bold && indent == 0 ? indent : indent + 1}
      >
        {"title" in form.pending && (
          <Title.Editor
            onUpdate={title => {
              form.setPending({ title });
            }}
          >
            {form.pending.title || ""}
          </Title.Editor>
        )}
        <ContentEditor
          onUpdate={content => {
            form.setPending({ content });
          }}
        >
          {form.pending.content || ""}
        </ContentEditor>
        <UserBadges user={user} />
        <Command
          icon="save"
          label="Save"
          onCommand={async () => {
            const result = await form.submit();
            let id = result.data.value;
            if (id && props.onCreate) {
              props.onCreate(id);
            }
          }}
        />
        <Command icon="cancel" label="Cancel" onCommand={form.toggle} />
      </Cell>
    )
  );
}

export function BoardCell(props: {
  entity: Board | Reply;
  bold?: boolean;
  indent?: number;
}) {
  const { entity, bold, indent } = props;
  const form = useEntityForm("createReply", () => ({
    parent: entity.id,
    content: ""
  }));
  const replies = useEntityCollection("Reply", entity.id);

  return (
    <>
      <Cell key="cell" bold={bold} indent={indent}>
        {"title" in entity && <Title>{entity.title}</Title>}
        <Content>{entity.content}</Content>
        <UserBadges user={entity.creator} />
        <Command icon="reply" label="Reply" onCommand={form.init} />
      </Cell>
      <NewBoard
        bold={bold}
        indent={indent == 0 ? indent : indent + 1}
        form={form}
      />
      {replies.map(reply => (
        <BoardCell
          key={`reply-${reply.id}`}
          bold={false}
          indent={bold && indent == 0 ? indent : indent + 1}
          entity={reply}
        />
      ))}
    </>
  );
}

export function BoardPage({ match }: { match: any }) {
  const id = match.params.id;
  const [entity] = useEntityModel("Board", id);

  //TODO: Loading indictor (throw promise) w/ useModel
  if (!entity || !entity.id) {
    return null;
  }

  return <BoardCell entity={entity} indent={0} />;
}

export function BoardsPage({ match }: { match: any }) {
  const removeBoard = useMutationFunction("removeBoard");
  const boards = useEntityCollection("Board");
  const form = useEntityForm("createBoard", () => ({ content: "", title: "" }));

  return (
    <>
      <Cell key="cell" bold indent={0}>
        <Title>All Boards</Title>
        <Command icon="create" label="Create" onCommand={form.toggle} />
      </Cell>
      <NewBoard form={form} />
      {boards.map(board => (
        <Cell key={`board-${board.id}`} indent={0}>
          <Title>{board.title}</Title>
          <Content>{board.content}</Content>
          <UserBadges user={board.creator} />
          <Command
            icon="comment"
            label="Comments"
            to={`${match.path}/${board.id}`}
          />
          <Command
            icon="delete"
            label="Delete"
            onCommand={() => removeBoard({ id: board.id })}
          />
        </Cell>
      ))}
    </>
  );
}

thread(t => {
  t.path = "boards";
  t.label = "Boards";
  t.renderIndex = BoardsPage;
  t.renderItem = BoardPage;
});
