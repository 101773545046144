import build, { color } from "./build";

export const theme1 = build({
  // content: color("#f1f1f1", "#495057"),
  // primary: color("#1c7ed6", "#e7f5ff"),
  // secondary: color("#ff922b", "#fff4e6"),
  content: color("#f1f1f1"),
  primary: color("#1c7ed6"),
  secondary: color("#ff922b"),
  headerFontFamily: ["Open Sans", "sans-serif"],
  bodyFontFamily: ["Open Sans", "serif"],
  googleFonts: [
    {
      name: "Open Sans",
      styles: ["400", "400i", "700"]
    }
  ]
});

export const theme2 = build({
  content: color("#2d4059"),
  primary: color("#913535"),
  secondary: color("#393e46"),
  headerFontFamily: ["Open Sans", "sans-serif"],
  bodyFontFamily: ["Open Sans", "serif"],
  googleFonts: [
    {
      name: "Open Sans",
      styles: ["400", "400i", "700"]
    }
  ]
});

export const theme3 = build({
  content: color("#33313b"),
  primary: color("#3c4f65"),
  secondary: color("#834c69"),
  headerFontFamily: ["Open Sans", "sans-serif"],
  bodyFontFamily: ["Open Sans", "serif"],
  googleFonts: [
    {
      name: "Open Sans",
      styles: ["400", "400i", "700"]
    }
  ]
});
// export const theme2 = build({
//   content: color("white"),
//   primary: color("#1c7ed6"),
//   secondary: color("#ff922b"),
//   headerFontFamily: ["Open Sans", "sans-serif"],
//   bodyFontFamily: ["Open Sans", "serif"],
//   googleFonts: [
//     {
//       name: "Open Sans",
//       styles: ["400", "400i", "700"]
//     }
//   ]
// });

export default theme2;
