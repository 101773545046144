import _ from "lodash";
import * as context from "mm-context";
import { invariant } from "mm-core";
import { asyncToPromiseState, PromiseState, toPromiseState } from "mm-mobx";
import { computed, reaction } from "mobx";

export type DSCreate<T> = (ctx: Context) => T;

export type DSState<T> = PromiseState<T>;

export type DSExtra<T> = {
  state: () => DSState<T>;
  load: () => Promise<T>;
};

export type DS<T> = {
  (): T;
  state: () => DSState<T>;
  load: () => Promise<T>;
  // mount: () => Promise<void>,
  // unmount: () => Promise<void>
};

interface ArrayLike<T> {
  readonly length: number;
  readonly [n: number]: T;
}

type List<T> = ArrayLike<T>;
interface RecursiveArray<T> extends Array<T | RecursiveArray<T>> { }
interface ListOfRecursiveArraysOrValues<T>
  extends List<T | RecursiveArray<T>> { }

export type Key = ListOfRecursiveArraysOrValues<string>;

export const KEY = context.create1("key", "ROOT");

export const CACHE = context.create1("cache", {});

export function useCache<T>(fn: () => T, key: Key): T {
  const keystr = flattenKey([KEY.current, key as string[]]);
  const cache = CACHE.current;
  let result: undefined | { value: T } = cache[keystr];
  if (!result) {
    result = cache[keystr] = { value: fn() };
  }
  return result.value;
}

export function usePromiseState<R>(
  fn: () => Promise<R>,
  key: Key
): PromiseState<R> {
  return useCache(() => asyncToPromiseState(fn), key)();
}

export function usePromise<T>(fn: () => Promise<T>, key: Key): T {
  const state = usePromiseState(fn, key);
  if (state.type === "error") {
    throw state.error;
  }
  if (state.type === "pending") {
    // console.log("throw via value() due to pending");
    throw state.promise;
  }
  return state.value;
}

const PROMISES = context.create1("promises", [] as Array<Promise<void>>);

export function withPromises(mode: "throw" | "capture", fn: Function): void {
  const done = () => {
    const current = PROMISES.current;
    if (mode === "throw" && current.length > 0) {
      PROMISES([]);
      throw Promise.all(current);
    }
  };

  try {
    fn();
    done();
  } catch (error) {
    if (error && error.then != null) {
      const errorp: Promise<void> = error;
      PROMISES.current.push(errorp);
      // console.log(PROMISES.current.length, "promises", mode);
      done();
    } else {
      throw error;
    }
  }
}

export const noop = () => { };

function callable<T>(fn: () => T, extra: DSExtra<T>): DS<T> {
  const result = function(): T {
    return fn();
  };
  result.state = extra.state;
  result.load = extra.load;
  return result as DS<T>;
}

const UNMOUNTS = context.create1("unmounts", [] as Array<
  () => void | Promise<void>
>);

export function useUnmount(fn: () => void | Promise<void>, key?: Key) {
  useCache(
    () => {
      UNMOUNTS.current.push(fn);
    },
    ["useUnmount", (key || "default") as string]
  );
}

// export const allUnmounts: Array<() => void | Promise<void>> = [];

export async function unmountAll() {
  const current = [...UNMOUNTS.current];
  for (let fn of _.reverse(current)) {
    await fn();
  }
  while (current.length > 0) {
    current.pop();
  }
  UNMOUNTS([]);
}

export class Context {
  _cache: { [key: string]: any } = {};

  state<R>(name: string, fn: () => Promise<R>): PromiseState<R> {
    let result: () => PromiseState<R> | undefined = this._cache[name];
    if (result == null) {
      result = this._cache[name] = asyncToPromiseState(fn);
    }
    return result();
  }

  value<R>(name: string, fn: () => Promise<R>): R {
    const state = this.state(name, fn);
    if (state.type === "error") {
      throw state.error;
    }
    if (state.type === "pending") {
      // console.log("throw via value() due to pending");
      throw state.promise;
    }
    return state.value;
  }

  memo<T>(fn: () => T, key: Key, debug?: boolean): T {
    const keystr = _.flattenDeep(["memo", key]).join("|");
    let result: undefined | { value: T } = this._cache[keystr];
    if (!result) {
      result = this._cache[keystr] = { value: fn() };
    }
    return result.value;
  }

  computed<T>(fn: () => T, key: Key): T {
    const keystr = _.flattenDeep(["computed", key]).join("|");
    let result: undefined | DS<T> = this._cache[keystr];
    if (!result) {
      result = this._cache[keystr] = createRaw(this, () => fn());
    }
    return result();
  }

  promise<T>(fn: () => Promise<T>, key: Key): T {
    const keystr = _.flattenDeep(["promise", key]).join("|");
    const state = this.state(keystr, fn);
    if (state.type === "error") {
      throw state.error;
    }
    if (state.type === "pending") {
      // console.log("throw via value() due to pending");
      throw state.promise;
    }
    return state.value;
  }

  cache<T>(name: string, fn: () => Promise<T>, opts: { ttl?: number } = {}): T {
    const withExpires = async () => {
      const value = await fn();
      const expires = opts.ttl ? Date.now() + opts.ttl : null;
      return { value, expires };
    };

    let result = this.value(name, withExpires);
    const { expires } = result;

    //TODO: reactive time
    if (expires && Date.now() > expires) {
      delete this._cache[name];
      result = this.value(name, withExpires);
    }
    return result.value;
  }

  cache2<T>(fn: () => Promise<T>, opts: { ttl?: number } = {}, key: Key): T {
    const keystr = _.flattenDeep(["cache", key]).join("|");
    const withExpires = async () => {
      const value = await fn();
      const expires = opts.ttl ? Date.now() + opts.ttl : null;
      return { value, expires };
    };

    let result = this.value(keystr, withExpires);
    const { expires } = result;

    //TODO: reactive time
    if (expires && Date.now() > expires) {
      delete this._cache[keystr];
      result = this.value(keystr, withExpires);
    }
    return result.value;
  }

  unmounter: null | Function = null;
  registeredUnmounter = false;

  onUnmount(fn: () => void): void {
    this.unmounter = fn;
    if (!this.registeredUnmounter && fn) {
      this.registeredUnmounter = true;
      UNMOUNTS.current.push(async () => {
        await this.unmount();
      });
    }
  }

  unmount = async () => {
    const { unmounter } = this;
    if (unmounter != null) {
      await unmounter();
      this.unmounter = null;
    }
  };
}

export function load<F extends (...args: any[]) => Promise<any>>(fn: F): F {
  let result = async (...args: ArgumentTypes<F>): Promise<Unpack<F>> => {
    let i = 0;
    let errorp: null | Promise<void> = null;
    while (true) {
      // console.log("iteration ", i);
      i += 1;
      try {
        if (errorp != null) {
          // console.log("waiting for errorp", i);
          await errorp;
          // console.log("done waiting for errorp", i);
          errorp = null;
        }
        // console.log("waiting for result", i);
        const result = await fn(...args);
        // console.log("returning result", i);
        return result;
      } catch (error) {
        if (error && error.then != null) {
          errorp = error;
          // console.log("set errorp for ", i + 1);
        } else {
          // console.log("throwing error", i);
          throw error;
        }
      }
    }
    throw new Error("should never get here");
  };
  return result as F;
}

export function parallel<T>(items: Array<() => T>): Array<T> {
  if (items.length == 0) {
    return [];
  }

  let states = items.map(fn => toPromiseState(fn)());

  let result = _.groupBy(states, state => state.type);

  if (result.pending) {
    throw Promise.all(result.pending.map((state: any) => state.promise));
  }

  if (result.error) {
    console.error(result.error);
    throw new Error("parallel errors");
  }

  return result.resolved.map(state => state.value);
}

export function use<T>(ds: DS<T>): T {
  const state = ds.state();
  if (state.type !== "resolved") {
    throw ds.load();
  }
  return state.value;
}

export function fallback<T>(ds: DS<T>, fallback: T): T {
  const state = ds.state();
  if (state.type !== "resolved") {
    return fallback;
  }
  return state.value;
}

export type AnyArgs<T> = (...args: any) => T;

// export function load<F: Function & ((...args: any) => Promise<any>)>(fn: F): F {

export function createfn<F extends Function & ((...args: any) => any)>(
  createFn: (ctx: Context) => F
): (...args: ArgumentTypes<F>) => DS<ReturnType<F>> {
  const dsv = create(ctx => {
    return { ctx, fn: createFn(ctx) };
  });

  const result = (...args: ArgumentTypes<F>): DS<ReturnType<F>> => {
    const call = () => {
      const { fn } = dsv();
      return fn(...args);
    };

    const state = (): PromiseState<ReturnType<F>> => {
      try {
        const value = call();
        return { type: "resolved", value };
      } catch (error) {
        if (error && error.then != null) {
          // console.log("fetch has an errorp");
          const errorp: Promise<void> = error;
          return { type: "pending", value: undefined, promise: errorp };
        } else {
          return { type: "error", value: undefined, error };
        }
      }
    };

    const load = async () => {
      const statev = state();
      if (statev.type === "error") {
        throw statev.error;
      }
      if (statev.type === "pending") {
        await statev.promise;
        return await load();
      }
      return statev.value;
    };

    return callable(call, { state, load });
  };

  return result;
}

export function create<T>(createFn: (ctx: Context) => T): DS<T> {
  const id = _.uniqueId("createFn");
  let dsfn: () => DS<T> = _.memoize(() =>
    createRaw(new Context(), withRootKey2(createFn, id))
  );

  const result = callable(
    (): T => {
      const dsv: DS<T> = dsfn();
      const value: T = dsv();
      return value;
    },
    {
      state: () => dsfn().state(),
      load: () => dsfn().load()
    }
  );

  return result;
}

let __current_context: null | Context = null;

export function createRaw<T>(
  ctx: Context,
  createFn: (ctx: Context) => T
): DS<T> {
  const computedCreateFn = computed(
    toPromiseState(() => {
      let before = __current_context;
      __current_context = ctx;
      try {
        return createFn(ctx);
      } finally {
        __current_context = before;
      }
    })
  );

  //keep alive to get smart computed caching behavior
  UNMOUNTS.current.push(
    reaction(
      () => {
        try {
          return computedCreateFn.get();
        } catch (error) {
          return error;
        }
      },
      rez => {
        // console.log("always listening");
      }
    )
  );

  // const state = fromPromise(fetch);

  const state = (): PromiseState<T> => {
    return computedCreateFn.get();
  };

  const load = async () => {
    const statev = state();
    if (statev.type === "error") {
      throw statev.error;
    }
    if (statev.type === "pending") {
      await statev.promise;
      return await load();
    }
    return statev.value;
  };
  const mount = load;

  const result = callable(
    () => {
      const latest = state();
      if (latest.type === "pending") {
        throw latest.promise;
      }
      if (latest.type === "error") {
        throw latest.error;
      }
      return latest.value;
    },
    { state, load }
  );

  return result;

  // return {
  //   state,
  //   load,
  //   mount,
  //   unmount: ctx.unmount
  // };
}

function flattenKey(key: Key): string {
  const parts = _.flattenDeep([key]);
  return parts.join("|");
}

export function withRootKey<R>(fn: () => R, key: Key): () => R {
  return () => {
    let result: null | { value: R } = null;
    KEY(flattenKey(key), () => {
      result = { value: fn() };
    });
    invariant(result != null);
    return result.value;
  };
}

export function withRootKey2<F extends Function>(fn: F, key: Key): F {
  let fnr = (...args: ArgumentTypes<F>) => {
    let result: null | { value: any } = null;
    KEY(flattenKey(key), () => {
      result = { value: fn(...args) };
    });
    if (result == null) {
      throw new Error("expected result");
    }
    return result.value;
  };
  return (fnr as any) as F;
}

export function withSubKey<R>(fn: () => R, key: Key): () => R {
  return () => {
    let result: null | { value: R } = null;
    KEY(flattenKey([KEY.current, key as string[]]), () => {
      result = { value: fn() };
    });
    invariant(result != null);
    return result.value;
  };
}

export async function toAsync<R>(fn: () => R): Promise<R> {
  let i = 0;
  while (true) {
    i += 1;
    try {
      const result = fn();
      return result;
    } catch (error) {
      if (error && error.then != null) {
        const errorp: Promise<void> = error;
        await errorp;
      } else {
        throw error;
      }
    }
  }
  throw new Error("should never get here");
}

type SingletonResult = <T>(fn: () => T) => T;

export function singleton(name: string): SingletonResult {
  const id = _.uniqueId(name);

  return <T>(fn: () => T): T => {
    return withRootKey(fn, id)();
  };
}

export function wrap<F extends Function>(fn: F): F {
  const id = _.uniqueId("wrap");
  return withRootKey2(fn, id);
}

export function promise<R>(
  fn: () => Promise<R>,
  opts: { ttl?: number } = {}
): DS<R> {
  return create(ctx => {
    return ctx.cache("result", fn, opts);
  });
}
