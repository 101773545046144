import firebase from "firebase";
import React from "react";
import { UserContext } from "mm-firebase-client/hooks";

const { useState, useEffect } = React;

export interface Config {
  apiKey?: string;
  authDomain?: string;
  databaseURL?: string;
  projectId?: string;
  storageBucket?: string;
  messagingSenderId?: string;
}

export default function FirebaseAuthProvider(props: {
  config: Config;
  children: React.ReactNode;
}) {
  const { config } = props;
  const [user, setUser] = useState(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    console.log("initializeApp for firebase auth");
    firebase.initializeApp(config);
    setInitialized(true);
  }, [config]);

  useEffect(() => {
    const dispose = firebase.auth().onAuthStateChanged(user => {
      console.log("onAuthStateChanged", user);
      setUser(user);
    });
    return dispose;
  }, []);

  return (
    <UserContext.Provider value={user}>{props.children}</UserContext.Provider>
  );
}
