import FirebaseAuthProvider from "mm-firebase-client/components/FirebaseAuthProvider";
import "mm-hacker-news/threads";
import { Route } from "mm-router";
import { ThemeLoad, useTheme } from "mm-themes/components/Theme";
import Badge from "mm-threads/components/Badge";
import Cell from "mm-threads/components/Cell";
import Command from "mm-threads/components/Command";
import Loading from "mm-threads/components/Loading";
import Title from "mm-threads/components/Title";
import { useThreads } from "mm-threads/registry";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { View } from "react-native";
import ApolloProvider from "./components/ApolloProvider";
import Home from "./pages/Home";
import Beta from "./pages/Beta";
import Pricing from "./pages/Pricing";
import { PrivacyPolicy, TermsOfUse } from "./pages/Legal";
import Profile from "./pages/Profile";
import "./threads";
import { useRouter } from "mm-router/hooks";
import { beacon } from "mm-analytics";

const Suspense = React.Suspense;

const HEADER_ALLOW = process.env.NODE_ENV === "production" ? [] : ["*"];

const config = {
  apiKey: "AIzaSyD5gNVaw3tSEbmM5L3pq0PzHIfb7Io7NJk",
  authDomain: "informatted-688ee.firebaseapp.com",
  databaseURL: "https://informatted-688ee.firebaseio.com",
  projectId: "informatted-688ee",
  storageBucket: "informatted-688ee.appspot.com",
  messagingSenderId: "1011869219825"
};

export function Body() {
  const [theme] = useTheme();
  const threads = useThreads();

  const routes = threads.map(t => (
    <>
      <Route
        key={`route-index-${t.path}`}
        exact
        path={`/${t.path}`}
        component={t.renderIndex}
      />
      <Route
        key={`route-item-${t.path}`}
        exact
        path={`/${t.path}/:id`}
        component={t.renderItem}
      />
    </>
  ));

  let commands: Array<React.ReactElement<typeof Command>> = [];

  commands = [
    ...threads.map(t => {
      const allow =
        HEADER_ALLOW.indexOf(t.path) != -1 || HEADER_ALLOW.indexOf("*") != -1;
      if (!allow) {
        return null;
      }
      return (
        <Command
          key={`command-index-${t.path}`}
          icon={t.icon || "open-in-browser"}
          label={t.label}
          to={`/${t.path}`}
        />
      );
    })
  ];
  return (
    <View
      style={{
        minHeight: "100vh",
        flexDirection: "column",
        justifyContent: "flex-start"
      }}
    >
      <View>
        <Cell key="header" bold>
          <Title level={1}>informatted</Title>
          <Command key="command-home" icon="home" label="Home" to="/" />
          <Command
            key="command-pricing"
            icon="shop"
            label="Pricing"
            to="/pricing"
          />
          {commands}
        </Cell>
      </View>

      <View style={{ flex: 1, backgroundColor: theme.content.darker }}>
        <View>
          <Suspense key="content" fallback={<Loading />}>
            <Route exact path="/" component={Home} />
            <Route exact path="/beta" component={Beta} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/privacy" component={PrivacyPolicy} />
            <Route exact path="/terms" component={TermsOfUse} />
            <Route exact path="/pricing" component={Pricing} />
            {routes}
          </Suspense>
        </View>
      </View>

      <View
        style={{
          width: "100%",
          flexDirection: "row",
          justifyContent: "center"
        }}
      >
        <Cell>
          <Badge icon="copyright" label="Zigimus, LLC" to="/terms" />
          <Badge icon="fa5 award" label="Privacy Policy" to="/privacy" />
          <Badge icon="fa5 file-contract" label="Terms of Use" to="/terms" />
        </Cell>
      </View>
    </View>
  );
}

// font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;

export default function Router() {
  const [theme] = useTheme();
  const { history } = useRouter();

  useEffect(() => {
    history.listen(location => {
      const page = location.pathname + location.search;
      beacon("set", "page", page);
      beacon("send", "pageview");
      console.log("sending page view!!!", { page });
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>informatted</title>
      </Helmet>
      <ThemeLoad theme={theme} />
      <FirebaseAuthProvider config={config}>
        <ApolloProvider>
          <Body />
        </ApolloProvider>
      </FirebaseAuthProvider>
    </>
  );
}
