import _ from "lodash";
import deepmerge from "./deepmerge";

export { deepmerge };

export function invariant(
  predicate: boolean,
  message?: string,
  ...args: Array<any>
) {
  if (process.env.NODE_ENV !== "production") {
    if (!predicate) {
      const interpolated = args.reduce(
        (str, arg) => str.replace(/%s/, arg),
        message
      );
      const err = new Error(interpolated);
      err.name = "Invariant Violation";
      (err as any).framesToPop = 1;
      throw err;
    }
  }
}

export function isString(y) {
  return typeof y === "string";
}

export function isNumber(y) {
  return typeof y === "number";
}

export function isNumberOrString(y) {
  return isString(y) || isNumber(y);
}

export const entries = <T>(obj: { [key: string]: T }): Array<[string, T]> => {
  const keys: string[] = Object.keys(obj);
  return keys.map(key => [key, obj[key]] as [string, T]);
};

export const values = <T>(obj: { [key: string]: T }): Array<T> => {
  const keys: string[] = Object.keys(obj);
  return keys.map(key => obj[key]);
};

export const groupBy = _.groupBy;
// export const groupBy = <T>(
//   arr: Array<T>,
//   fn: T => string
// ): { [key: string]: Array<T> } => {
//   return _.groupBy(arr, fn);
// };

export function deepequal(
  obj1: undefined | null | Object,
  obj2: undefined | null | Object
): boolean {
  return _.isEqual(obj1, obj2);
}

export function extractStrings(
  obj: Object,
  fn?: (arg: { path: string; value: string }) => unknown
): { path: string; value: string }[] {
  const result = [];
  const xfn =
    fn == null
      ? obj => {
        result.push(obj);
      }
      : fn;

  for (let path in obj) {
    const value = obj[path];
    if (_.isString(value)) {
      xfn({ path, value });
    }
    if (_.isPlainObject(value)) {
      extractStrings(value, obj => {
        xfn({ ...obj, path: `${path}.${obj.path}` });
      });
    }
  }

  return result;
}

export class Store<Props extends {}> {
  props: Props;

  constructor(props?: Props) {
    this.props = { ...props };
  }
}

const onlyIfDefault: (Error) => boolean = _error => true;

export async function retry<T>(
  fn: (...args: any[]) => Promise<T>,
  retriesLeft = 5,
  interval = 1000,
  exponential = false,
  onlyif = onlyIfDefault
): Promise<T> {
  try {
    const val = await fn();
    return val;
  } catch (error) {
    if (retriesLeft && onlyif(error)) {
      console.log("retry with ", { retriesLeft, interval });
      await new Promise(r => setTimeout(r, interval));
      return retry(
        fn,
        retriesLeft - 1,
        exponential ? interval * 2 : interval,
        exponential
      );
    } else throw error;
  }
}
