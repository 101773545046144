import Dependencies from "mm-lifecycle/Dependencies";
import Lifecycle from "mm-lifecycle/Lifecycle";
import {
  extendObservable,
  IObservableFactories,
  IReactionOptions,
  observable,
  ObservableMap,
  reaction,
  _allowStateChangesInsideComputed
} from "mobx";

const observablef: IObservableFactories = observable;

export const box = observablef.box;

export function ovalues<V>(omap: ObservableMap<any, V>): Array<V> {
  return [...omap.values()];
}

// export { observablef };
//
// export function box<T>(v: T): IObservableValue<T> {
//   const result: IObservableValue<T> = (observable).shallowBox(v);
//   return (result: IObservableValue<T>);
// }

export function changes<T>(
  track: () => T,
  handle: (latest: T, previous?: T) => void,
  opts?: IReactionOptions
): Lifecycle {
  let dispose: null | Function = null;
  let previous = undefined;
  return Lifecycle.inline({
    didMount: () => {
      dispose = reaction(
        track,
        result => {
          try {
            handle(result, previous);
            previous = result;
          } catch (e) {
            console.warn(e);
          }
        },
        opts || undefined
      );
    },
    willUnmount: () => {
      if (dispose != null) {
        dispose();
        dispose = null;
      }
    }
  });
}

export function lazy(
  target: Object,
  prop: string | symbol,
  descriptor?: PropertyDescriptor
): any {
  // console.log("lazy called with ", arguments);
  Dependencies.register(target);
  return {
    get: function() {
      return this.deps.getOrCreate(this, prop, descriptor, "@lazy");
    },
    enumerable: descriptor.enumerable,
    configurable: true
  } as any;
}

export function provided(
  target: any,
  prop: string,
  descriptor: PropertyDescriptor
) {
  Dependencies.register(target);
  return {
    get: function() {
      return this.deps.provide(this, prop, descriptor);
    },
    enumerable: descriptor.enumerable,
    configurable: true
  };
}

provided.mount = function(
  target: any,
  prop: string,
  descriptor: PropertyDescriptor
) {
  // console.log("mount called with ", arguments);
  Dependencies.register(target);
  Dependencies.registerForMount(target, prop);
  return {
    get: function() {
      return this.deps.provide(this, prop, descriptor, true);
    },
    enumerable: descriptor.enumerable,
    configurable: true
  };
};

export function mount(
  target: any,
  prop: string,
  descriptor: PropertyDescriptor
) {
  // console.log("mount called with ", arguments);
  Dependencies.register(target);
  Dependencies.registerForMount(target, prop);
  return {
    get: function() {
      return this.deps.getOrCreate(this, prop, descriptor, "@mount");
    },
    enumerable: descriptor.enumerable,
    configurable: true
  };
}

export function waitMs(durationMs: number): Promise<boolean> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(true);
    }, durationMs);
  });
}

export type PromiseState<T> =
  | { type: "resolved"; value: T }
  | { type: "pending"; value?: undefined; promise: Promise<void> }
  | { type: "error"; value?: undefined; error: Error };

export function wrapPending<T>(
  fn: () => PromiseState<T>
): () => PromiseState<T> {
  let state: { pending: null | PromiseState<T> } = extendObservable(
    {},
    {
      pending: null
    }
  );

  return () => {
    if (state.pending) {
      return state.pending;
    }
    const result: PromiseState<T> = fn();
    if (result.type === "pending") {
      let promisefn = async () => {
        let rez: PromiseState<T> = result;

        let count = 0;
        while (rez.type === "pending") {
          count += 1;
          // console.log("waiting for pending", count);
          await rez.promise;
          rez = fn();
        }

        // console.log("done waiting for pending!", count);

        _allowStateChangesInsideComputed(() => {
          state.pending = null;
        });
      };

      const pending: PromiseState<T> = {
        type: "pending",
        promise: promisefn()
      };

      _allowStateChangesInsideComputed(() => {
        state.pending = pending;
      });

      return pending;
    }
    return result;
  };
}

export function asyncToPromiseState<T>(
  fn: () => Promise<T>
): () => PromiseState<T> {
  let state: { latest?: PromiseState<T> } = extendObservable({}, {});

  async function init() {
    try {
      const value = await fn();
      _allowStateChangesInsideComputed(() => {
        state.latest = { type: "resolved", value };
      });
    } catch (error) {
      _allowStateChangesInsideComputed(() => {
        state.latest = { type: "error", value: undefined, error };
      });
      throw error;
    }
  }

  state.latest = { type: "pending", value: undefined, promise: init() };

  return wrapPending(() => {
    return state.latest;
  });
}

export function toPromiseState<T>(fn: () => T): () => PromiseState<T> {
  return wrapPending(() => {
    try {
      const value = fn();
      return { type: "resolved", value };
    } catch (error) {
      if (error && error.then != null) {
        const errorp: Promise<void> = error;
        return { type: "pending", value: undefined, promise: errorp };
      } else {
        return { type: "error", value: undefined, error };
      }
    }
  });
}
