import React from "react";
import { useTheme, ColorType } from "./Theme";

const { useContext } = React;
export const ColorContext = React.createContext<null | ColorType>(null);

export function useColor(): ColorType {
  const [theme] = useTheme();
  const color = useContext(ColorContext);
  return color != null ? color : theme.content;
}

export default function Color(props: {
  color: ColorType;
  children: React.ReactNode;
}) {
  return (
    <ColorContext.Provider value={props.color}>
      {props.children}
    </ColorContext.Provider>
  );
}
