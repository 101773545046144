import _ from "lodash";
import qs from "qs";
import { buildURL } from "mm-core/url";

class ApiError extends Error {
  params;
  source;
  constructor(params, source) {
    super(`ApiError: ${params.type}`);
    this.params = params;
    this.source = source;
  }
}

export type Params = Partial<{
  method: "GET" | "POST" | "PUT";
  url: string;
  apiRoot: string;
  path: string;
  params: object;
  data: string | object;
  form: object;
  headers: { [key: string]: string };
  userAgent: string;
  timeout: number;
  throwOnError: boolean;
  json: boolean;
  contentType: boolean;
}>;

export async function apiCall({
  method = "GET",
  url,
  apiRoot,
  path,
  params,
  data,
  form,
  headers,
  userAgent,
  timeout = 0,
  throwOnError = false,
  json = true,
  contentType = true
}: Params) {
  let config: RequestInit & { timeout?: number } = {
    method,
    headers: {
      Accept: "application/json",
      ...(contentType ? { "Content-Type": "application/json" } : null),
      "User-Agent": userAgent,
      ...(userAgent ? { "User-Agent": userAgent } : null),
      ...headers
    }
  };

  if (data) {
    if (_.isPlainObject(data)) {
      config.body = JSON.stringify(data);
    } else {
      //@ts-ignore
      config.body = data;
    }
  }

  if (form) {
    config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    config.body = qs.stringify(form, { format: "RFC1738" });
  }

  if (timeout > 0) {
    config.timeout = timeout;
  }

  let base;

  if (apiRoot) {
    if (!path.startsWith("/")) {
      path = "/" + path;
    }
    base = `${apiRoot}${path}`;
  } else {
    base = url;
  }

  url = buildURL(base, params);

  // console.log("making request", { url, config });
  let response;

  try {
    // Hack: extraneous then() call is a temporary fix for this issue
    // https://github.com/facebook/react-native/issues/6679
    response = await fetch(url, config).then(response => {
      setTimeout(() => null, 0);
      return response;
    });
  } catch (e) {
    const error = { type: "io" };
    if (throwOnError) {
      throw new ApiError(error, e);
    }
    console.warn(e.stack);
    return {
      ok: false,
      error
    };
  }

  const ok = response.ok;
  const status = response.status;

  let body = null;
  if (json) {
    try {
      body = await response.json();
    } catch (e) {
      // console.warn(e.stack);
      if (ok) {
        const error = { type: "json", message: e.message };
        if (throwOnError) {
          throw new ApiError(error, e);
        }
        return { ok: false, error };
      }
    }
  } else {
    body = await response.text();
  }

  if (!ok) {
    const error = {
      type: "http",
      status,
      body,
      url,
      path,
      params,
      method,
      data
    };

    if (throwOnError) {
      throw new ApiError(error, null);
    }

    // console.warn(error);
    console.log("http error", { error, config, url: response.url });
    return { body, status, ok: false, error, url: response.url };
  }

  // console.log("got response", {body, status, ok})

  return { body, status, ok };
}

export async function get(params) {
  return await apiCall({ ...params, method: "GET" });
}

export async function post(params) {
  return await apiCall({ ...params, method: "POST" });
}
