import React from "react";
import {
  BrowserRouter,
  Link as LinkBase,
  Route,
  Switch
} from "react-router-dom";
export { BrowserRouter, Route, Switch };

export default function Link({
  to,
  children,
  ...props
}: React.ComponentProps<typeof LinkBase> & {
  to: string;
  children: React.ReactNode;
}) {
  // It is a simple element with nothing to link to
  if (!to) return <span {...props}>{children}</span>;

  // It is intended to be an external link
  if (/^https?:\/\//.test(to))
    return (
      <a href={to} target="_blank" {...props}>
        {children}
      </a>
    );

  // Finally, it is an internal link
  return (
    <LinkBase to={to} {...props}>
      {children}
    </LinkBase>
  );
}
