import React from "react";
import Cell from "mm-threads/components/Cell";

class Store<SProps> {
  props: SProps;

  constructor(props?: SProps) {
    this.props = { ...props };
  }
}

export type Props = {
  path: string;
  label: string;
  icon?: string;
  renderIndex: React.ComponentType<{ match?: any }>;
  renderItem: React.ComponentType<{ match?: any }>;
};

export type Context = Partial<Props>;

export default class ThreadStore extends Store<Props> {
  path = this.props.path;
  label = this.props.label;
  icon = this.props.icon;
  renderIndex = this.props.renderIndex;
  renderItem = this.props.renderItem;

  static create(input: Context): ThreadStore {
    let { path, label, icon, renderIndex, renderItem } = input;
    if (path == null) throw new Error("must have path");
    if (label == null) throw new Error("must have label");
    if (renderIndex == null) throw new Error("must have renderIndex");
    if (renderItem == null) throw new Error("must have renderItem");
    return new ThreadStore({ path, label, icon, renderIndex, renderItem });
  }
}
